import { Dialog, DialogActions, DialogContent, Typography, withStyles } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React, { Fragment } from 'react';
import AppContext from '../../AppContext';
import { getResource } from '../../helper/ResourcesHelper';
import { generateCode, validateCode } from '../../networking/NetworkingMobileValidation';
import { getStyle } from '../../styles/styles';
import AlertBox from './AlertBox';
const style = getStyle();

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

export default class ValidatePhone extends React.Component {
    static contextType = AppContext;
    state = {
        token: '',
        phone: '',
        companyName:'',
        showSuccessAlert: false,
        alertTitle: '',
        alertMessage: '',
        phoneValidated: false,
        bypassPhoneValidation: false
    }

    componentDidUpdate(prevProps) {
        if (prevProps.phone !== this.props.phone) {
            this.setState({ phone: this.props.phone, companyName: this.props.companyName });
        }
    }

    validatePhone = async () => {
        const model = {
            numberMobile: this.state.phone,
            token: this.state.token
        };
        const json = await validateCode(model);
        if (json.httpStatusCode !== 200) {
            this.context.showAlert('Error', json.Message);
        } else {
            if (json.validation) {
                this.setState({ showSuccessAlert: true, alertTitle: 'Success', alertMessage: 'Code validated.', phoneValidated: true });
            } else {
                this.context.showAlert('Error', 'Code invalid.');
            }
        }
    }

    sendCode = async () => {
        const model = {
            numberMobile: this.state.phone,
            token: '',
            companyName: this.state.companyName != null ? this.state.companyName : ''
        };
        const json = await generateCode(model)
        if (json.httpStatusCode !== 200) {
            this.context.showAlert('Error', json.Message);
        } else {
            this.context.showAlert('Success', 'New code sent.');
        }
    }

    exit = () => {
        this.setState({ token: '' });
        this.props.onSuccess(this.state.phoneValidated, this.state.bypassPhoneValidation);
    }

    handleChange = (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({ token: event.target.value });
        }
    }

    closeSuccessAlert = () => {
        this.setState({ showSuccessAlert: false });
        this.exit();
    }

    byPassValidation = () => {
        this.setState({ bypassPhoneValidation: true },
            () => this.exit()
        );
    }

    render() {
        return (
            <Fragment>
                <Dialog open={this.props.open} onClose={this.exit} maxWidth="sm" fullWidth={true}>
                    <DialogTitle key="title" id="alert-dialog-title-validate">{getResource('Label_MobileValidation')}</DialogTitle>
                    <DialogContent dividers>
                        <div className="uk-form-stacked">
                            <div className="uk-form-controls">
                                <div className="uk-grid">
                                    
                                    <div className="uk-width-1-3 uk-form-stacked">
                                        <input id="code" className="uk-input" placeholder="Code" maxLength={5} value={this.state.token} onChange={this.handleChange} />
                                    </div>
                                    
                                    <div className="uk-width-1-3 uk-form-stacked">
                                        <button className="btn btn-primary" key={"Validate-btn"} style={style.resendCodeButton} type="button" onClick={this.sendCode}>{getResource('Button_Resend')}</button>
                                    </div>
                                    <div className="uk-width-1-3 uk-form-stacked"></div>
                                    <div className="uk-width-3-3 uk-form-stacked" style={{fontSize:'14px', fontStyle:'italic', marginTop:'15px',marginBottom:'15px'}}>{getResource('Label_MobileValidationInstructions')}</div>
                                    
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button className="btn btn-primary" key={"ByPass"} type="button" onClick={this.byPassValidation}>{getResource('Button_ByPass')}</button>
                        {!this.props.bypassPhoneValidationConst && 
                        <button className="btn btn-primary" key={"Validate"} type="button" onClick={this.validatePhone}>{getResource('Button_Validate')}</button>
                        }
                        <button className="btn btn-default" key={"Exit"} type="button" onClick={this.exit}>{getResource('Button_Cancel_2')}</button>
                    </DialogActions>
                </Dialog>
                <AlertBox open={this.state.showSuccessAlert} onClose={this.closeSuccessAlert} title={this.state.alertTitle} message={this.state.alertMessage} type="Ok" okClick={this.closeSuccessAlert} />
            </Fragment>
        );
    }
}