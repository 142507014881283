import React from 'react';
import UIkit from 'uikit';
import AppContext from '../../AppContext';
import { getCurrentCompanyName } from '../../helper/PackageJsonHelper';
import { getResource, getURLTermsANDCondition } from '../../helper/ResourcesHelper';
import { checkValidReferralCustomer, getCustomerInfoByID, getParameterByName, getParametersByName } from '../../networking/Networking';
import { getAdditionalPropertiesDefs, getEntityProperties } from '../../networking/NetworkingAdditionalPropertiesDefs';
import { getAttachmentsBySubEntityType } from '../../networking/NetworkingAttachments';
import { saveOwners, sendCompleteRegistrationEmail, sendCustomerRegistrationEmail, sendValidateEmail, submit360CorpForm } from '../../networking/NetworkingRegForm';
import AlertBox from '../shared/AlertBox';
import LoadingBox from '../shared/LoadingBox';
import AccountPrimaryContact from './AccountPrimaryContact';
import AdditionalInformation from './AdditionalInformation';
import CustomerAgreement, { getPDF } from './CustomerAgreement';
import DirectorAndAppointedOfficer from './DirectorAndAppointedOfficer';
import GeneralBusinessInformation from './GeneralBusinessInformation';
import Owners from './Owners';
import PrincipalPlaceOfBusiness from './PrincipalPlaceOfBusiness';
import TaskAttachments from './TaskAttachments';


const documentName1 = 'Identity Card';
const front1 = 'Personal Identification document - 1';
const back1 = 'Personal Identification document - 2';
const documentName2 = 'Driving License';
const front2 = 'Driving License Document - 1';
const back2 = 'Driving License Document - 2';
const documentName3 = 'Passport';
const front3 = 'Personal Passport Document - 1';
const personalIdentificationAdditional = 'Personal Identification document - Add. Pages';
const drivinLicenseAdditional = 'Driving License Document - Add. Pages';
const passportAdditional = 'Personal Passport Document - Add. Pages';

//To Online Login
const onlineLoginUrl = window.REACT_APP_ONLINE_URL;
export default class CorporateScreen extends React.Component {
    static contextType = AppContext;

    state = {
        activeTab: 'general-business-information',

        //OBJFILTERS
        generalbusinessinformation: null,
        principalplaceofbusiness: null,
        productsandservices: null,
        foreignexchangeforwards: null,
        accountprimarycontact: null,
        directorandappointedofficer: null,
        ultimatebeneficialowner: null,
        owners: null,
        //acceptanceoftermsandconditions: null,

        objGeneralbusinessinformation: null,
        objGrincipalplaceofbusiness: null,
        objGroductsandservices: null,
        objGoreignexchangeforwards: null,
        objGccountprimarycontact: null,
        objGirectorandappointedofficer: null,
        objGltimatebeneficialowner: null,
        objGOwners: null,
        //objGcceptanceoftermsandconditions: null,

        //OBJFILTERS
        PreliminaryDetailsCorporate: null,
        CompanyDetails: null,
        DirectorsDetails: null,
        ShareholderOwner: null,
        TradingRequirements: null,
        TaskAttachments: null,
        CustomerAgreement: null,

        objPreliminaryDetailsCorporate: null,
        objCompanyDetails: null,
        objDirectorsDetails: null,
        objShareholderOwner: null,
        objTradingRequirements: null,
        objCustomerAgreement: null,
        objTaskAttachments: null,

        emailVerified: '',
        emailVerifiedSubmit: false,

        showSuccessAlert: false,
        alertTitle: '',
        alertMessage: '',

        //Alert Send Email
        showSentEmailAlert: false,
        alertTitleSentEmail: '',
        alertMessageSentEmail: '',
        // referral
        showInvalidReferralAlert: false,
        saveType: '', // 'full' or 'partial',
        validReferral: false, // If false, saves AffiliateReferredBy with null
        showFinalSubmitAlert: false,

        loading: false,
        readOnlyDirector: false,
        yesDirector: true,
        noDirector: false,
        readOnlyUltimate: false,
        yesUltimate: true,
        noUltimate: false,
        targetID: 'general-business-information-li',
        clickNext: false,

        customer: null,

        //------------Attachments---------
        TaskAttachments_Step1: null,
        TaskAttachments_Step2: null,
        TaskAttachments_Step3: null,
        TaskAttachments_Step4: null,
        TaskAttachments_Step5: null,
        TaskAttachments_Step6: null,
        TaskAttachments_Step7: null,
        attachmentGenerate_Step1: [],
        objTaskAttachments_Step1: [],
        attachmentGenerate_Step2: [],
        objTaskAttachments_Step2: [],
        attachmentGenerate_Step3: [],
        objTaskAttachments_Step3: [],
        attachmentGenerate_Step4: [],
        objTaskAttachments_Step4: [],
        attachmentGenerate_Step5: [],
        objTaskAttachments_Step5: [],
        attachmentGenerate_Step6: [],
        objTaskAttachments_Step6: [],
        attachmentGenerate_Step7: [],
        objTaskAttachments_Step7: [],
        tabIndex: 0,
        nextTabIndex: 0,

        //Additional
        valuesOfAdditionalPropertiesDefs1: [],
        valuesOfAdditionalPropertiesDefs2: [],
        valuesOfAdditionalPropertiesDefs3: [],
        valuesOfAdditionalPropertiesDefs4: [],
        valuesOfAdditionalPropertiesDefs5: [],
        valuesOfAdditionalPropertiesDefs6: [],
        valuesOfAdditionalPropertiesDefs7: [],
        valuesOfAdditionalPropertiesDefs8: [],

        validationsOfAdditionalPropertiesDefs1: [],
        validationsOfAdditionalPropertiesDefs2: [],
        validationsOfAdditionalPropertiesDefs3: [],
        validationsOfAdditionalPropertiesDefs4: [],
        validationsOfAdditionalPropertiesDefs5: [],
        validationsOfAdditionalPropertiesDefs6: [],
        validationsOfAdditionalPropertiesDefs7: [],
        validationsOfAdditionalPropertiesDefs8: [],

        allAdditionalPropertiesDefs1: [],
        allAdditionalPropertiesDefs2: [],
        allAdditionalPropertiesDefs3: [],
        allAdditionalPropertiesDefs4: [],
        allAdditionalPropertiesDefs5: [],
        allAdditionalPropertiesDefs6: [],
        allAdditionalPropertiesDefs7: [],
        allAdditionalPropertiesDefs8: [],
        attachmentGenerate: [],

        customerAdditionalProperties: null,
        contactAdditionalProperties: null,
        contact01AdditionalProperties: null,
        contact02AdditionalProperties: null,

        buttonPressed: false,
        helper_Step: []
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        sessionStorage.setItem('Country', null)
        sessionStorage.setItem('signatureImg', null)
        sessionStorage.setItem('signatureDate', '')
        sessionStorage.setItem('signatureFullName', '')
        sessionStorage.setItem('signatureTitle', '')
        let country = '', id = '';
        getParametersByName('CompanyContactUsEmailAddress,CompanyAddress,CompanyName,CompanyTelephoneNumber').then(
            (json)=>{
                let companyName = '';
                let companyAddress = '';
                let companyTelephone = '';
                let companyEmail = '';
                if(json && json.parameters)
                {
                    companyName = json.parameters.find(p => p.Name === 'CompanyName').Value;
                    companyAddress = json.parameters.find(p => p.Name === 'CompanyAddress').Value;
                    companyTelephone = json.parameters.find(p => p.Name === 'CompanyTelephoneNumber').Value;
                    companyEmail = json.parameters.find(p => p.Name === 'CompanyContactUsEmailAddress').Value;
                    sessionStorage.setItem('CompanyName', companyName)
                    sessionStorage.setItem('CompanyAddress', companyAddress)
                    sessionStorage.setItem('CompanyTelephone', companyTelephone)
                    sessionStorage.setItem('CompanyEmail', companyEmail)
                }
        })                    
        if (this.props.location.state != null && this.props.location.state.customer != null) {
            country = this.props.location.state.customer.CustomerAddresses[0].countryid;
            id = this.props.location.state.customer.CustomerAddresses[0].id;
            sessionStorage.setItem('Country', country)
            const customerAdditionalProperties = this.props.location.state.customer.AdditionalProperties;
            //--TermsPDF
            // debugger;
            let urlTermsPDF = getURLTermsANDCondition('Corporate', 'GBR');
            sessionStorage.setItem('360Language', !this.props.location.state.customer.PreferredLanguage.includes('Select') ? this.props.location.state.customer.PreferredLanguage : 'English')
            this.setState({
                customer: this.props.location.state.customer,
                customerAdditionalProperties,
                urlTermsPDF,
            }, () => {
                window.scrollTo(0, 0);
                this.adaptList(this.props.location.state.customer, this.props.location.state.originOnline);
            });
        }
        this.getAttachments('Customer - ' + this.props.type, country)
        this.getAttachments('Contact', country)
        if (country !== '') {
            window.scrollTo(0, 0)
            this.getAddProperties(country);
            this.getAddPropertiesByCountry(id);
        }
        UIkit.util.on('#accordion-contact', 'beforeshow', (event) => {
            //debugger
            this.setState({ targetID: event.target.id })
            switch (event.target.id) {
                case 'principal-place-of-business-li':
                    this.validateErrors(event, 1);
                    break;
                case 'account-primary-contact-li':
                    this.validateErrors(event, 2);
                    break;
                case 'directors-li':
                    this.validateErrors(event, 3);
                    break;
                case 'owners-li':
                    this.validateErrors(event, 4);
                    break;
                case 'acceptance-of-terms-and-conditions-li':
                    if (this.state.yesUltimate) {
                        this.validateErrors(event, 5);
                    } else { this.validateErrors(event, 5); }
                    break;
                default:
                    break;
            }
        });
        UIkit.util.on('#accordion-contact', 'beforehide', (event) => {
            //debugger
            const validation = this.state.buttonPressed ?
                this.state.nextTabIndex > this.state.tabIndex :
                this.transformToIndex(this.state.targetID) > this.transformToIndex(event.target.id);
            if (validation) {
                if (this.state.buttonPressed || this.transformToIndex(this.state.targetID) - this.transformToIndex(event.target.id) === 1) {
                    switch (event.target.id) {
                        case 'general-business-information-li':
                            this.validateErrors(event, 1);
                            break;
                        case 'principal-place-of-business-li':
                            this.validateErrors(event, 2);
                            break;
                        case 'account-primary-contact-li':
                            this.validateErrors(event, 3);
                            break;
                        case 'directors-li':
                            this.validateErrors(event, 4);
                            break;
                        case 'owners-li':
                            this.validateErrors(event, 5);
                            break;
                        case 'acceptance-of-terms-and-conditions-li':
                            if (this.state.yesUltimate) {
                                this.validateErrors(event, 6);
                            } else { this.validateErrors(event, 6); }
                            break;
                        default:
                            break;
                    }
                } else {
                    this.validateErrors(event, this.transformToIndex(this.state.targetID) - 1);
                }
            }
            if (this.state.buttonPressed) {
                this.setState({ buttonPressed: false });
            }
            //this.setState({ tabIndex: this.state.nextTabIndex });
        });
    }
    addProps_CustomerAgreement = (obj) => {
        this.setState({ addProps_step6: obj })
    }
    getAddPropertiesByCountry = (country) => {
        getEntityProperties(country, 'Country').then(
            (jsonResponse) => {
                window.scrollTo(0, 0)
                let region = '', urlTermsPDF = '', url = '';
                if (jsonResponse.totalCount > 0) {
                    jsonResponse.entityProperties.forEach(element => {
                        switch (element.Description) {
                            case 'Regions':
                                region = element.TextValue != null ? element.TextValue : sessionStorage.getItem('360Language');
                                this.props.navigate(this.props.location.pathname.split(':regionID')[0] + region);
                                break;
                            case 'Terms & Conditions Corporate':
                                if (country === 37) { url = element.TextValue.split(', '); }
                                urlTermsPDF = country === 37 ? url[0] : element.TextValue;
                                break;
                            /* case 'Terms & Condition Individual':
                                if (country === 37) { url = element.TextValue.split(', '); }
                                urlTermsPDF = country === 37 ? url[0] : element.TextValue;
                                break; */
                            default:
                                break;
                        }
                        this.setState({ region, urlTermsPDF })
                    })
                }
            }
        );
    }
    getAttachments = (customerType, country) => {
        getAttachmentsBySubEntityType(customerType, country).then(
            (json) => {
                window.scrollTo(0, 0)
                if (json.totalCount > 0) {
                    if (customerType === 'Customer - Corporate') {
                        json.attachments.forEach(element => {
                            switch (element['Display Order']) {
                                case 1:
                                    window.scrollTo(0, 0)
                                    this.setState({ attachmentGenerate_Step1: [element] })
                                    break;
                                case 2:
                                    window.scrollTo(0, 0)
                                    this.setState({ attachmentGenerate_Step2: [element] })
                                    break;
                                case 3:
                                    window.scrollTo(0, 0)
                                    this.setState({ attachmentGenerate_Step3: [element] })
                                    break;
                                case 4:
                                    window.scrollTo(0, 0)
                                    this.setState({ attachmentGenerate_Step4: [element] })
                                    break;
                                default:
                                    break;
                            }
                        });
                    } else if (customerType === 'Customer - MSB') {
                        json.attachments.forEach(element => {
                            switch (element['Display Order']) {
                                case 1:
                                    window.scrollTo(0, 0)
                                    this.setState({ attachmentGenerate_Step1: [element] })
                                    break;
                                case 2:
                                    window.scrollTo(0, 0)
                                    this.setState({ attachmentGenerate_Step2: [element] })
                                    break;
                                case 3:
                                    window.scrollTo(0, 0)
                                    this.setState({ attachmentGenerate_Step3: [element] })
                                    break;
                                case 4:
                                    window.scrollTo(0, 0)
                                    this.setState({ attachmentGenerate_Step4: [element] })
                                    break;
                                default:
                                    break;
                            }
                        });
                    } else {
                        json.attachments.forEach(element => {
                            switch (element['Display Order']) {
                                case 5:
                                    window.scrollTo(0, 0)
                                    this.setState({ attachmentGenerate_Step5: [element] })
                                    break;
                                case 6:
                                    window.scrollTo(0, 0)
                                    this.setState({ attachmentGenerate_Step6: [element] })
                                    break;
                                case 7:
                                    window.scrollTo(0, 0)
                                    this.setState({ attachmentGenerate_Step7: [element] })
                                    break;
                                default:
                                    break;
                            }
                        });
                    }
                }
            }
        );
    }
    getAddProperties = (country) => {
        //getAdditionalPropertiesDefs(country, 'Corporate', 1).then(
        getAdditionalPropertiesDefs(country, 'Customer - ' + this.props.type, 1).then(
            (jsonResponse) => {
                window.scrollTo(0, 0)
                console.log("getAdditionalPropertiesDefs: " + JSON.stringify(jsonResponse));
                let objAll = [...this.state.allAdditionalPropertiesDefs1];
                let objAll2 = [...this.state.allAdditionalPropertiesDefs2];
                let objAll4 = [...this.state.allAdditionalPropertiesDefs4];

                if (jsonResponse.additionalPropertiesDef != null) {
                    objAll = jsonResponse.additionalPropertiesDef.filter(element => element.pageNumber === 1).sort((a, b) => a.displayOrder - b.displayOrder);
                    objAll2 = jsonResponse.additionalPropertiesDef.filter(element => element.pageNumber === 2).sort((a, b) => a.displayOrder - b.displayOrder);
                    objAll4 = jsonResponse.additionalPropertiesDef.filter(element => element.pageNumber === 4).sort((a, b) => a.displayOrder - b.displayOrder);
                }
                this.setState({
                    allAdditionalPropertiesDefs1: objAll,
                    allAdditionalPropertiesDefs2: objAll2,
                });
            }
        );
        getAdditionalPropertiesDefs(country, 'Contact - ' + this.props.type, 1).then(
            (jsonResponse) => {
                window.scrollTo(0, 0)
                console.log("getAdditionalPropertiesDefs: " + JSON.stringify(jsonResponse));
                let objAll3 = [...this.state.allAdditionalPropertiesDefs5];

                if (jsonResponse.additionalPropertiesDef != null) {
                    objAll3 = jsonResponse.additionalPropertiesDef.filter(element => element.pageNumber === 3).sort((a, b) => a.displayOrder - b.displayOrder);
                }
                this.setState({
                    allAdditionalPropertiesDefs3: objAll3,
                });
            }
        );
    }
    componentDidUpdate(prevProps) {
        const changeLanguage = sessionStorage.getItem('ChangeLanguage');
        if (changeLanguage === 'true') {
            sessionStorage.setItem('ChangeLanguage', false);
            let country = '';
            if (this.state.customer != null) {
                country = this.state.customer.CustomerAddresses[0].countryid;
                if (country !== '') {
                    this.context.clearAllValidations()
                    this.setState({
                        //Additional
                        valuesOfAdditionalPropertiesDefs1: [],
                        valuesOfAdditionalPropertiesDefs2: [],
                        valuesOfAdditionalPropertiesDefs3: [],
                        valuesOfAdditionalPropertiesDefs4: [],
                        valuesOfAdditionalPropertiesDefs5: [],
                        valuesOfAdditionalPropertiesDefs6: [],
                        valuesOfAdditionalPropertiesDefs7: [],
                        valuesOfAdditionalPropertiesDefs8: [],

                        validationsOfAdditionalPropertiesDefs1: [],
                        validationsOfAdditionalPropertiesDefs2: [],
                        validationsOfAdditionalPropertiesDefs3: [],
                        validationsOfAdditionalPropertiesDefs4: [],
                        validationsOfAdditionalPropertiesDefs5: [],
                        validationsOfAdditionalPropertiesDefs6: [],
                        validationsOfAdditionalPropertiesDefs7: [],
                        validationsOfAdditionalPropertiesDefs8: [],

                        allAdditionalPropertiesDefs1: [],
                        allAdditionalPropertiesDefs2: [],
                        allAdditionalPropertiesDefs3: [],
                        allAdditionalPropertiesDefs4: [],
                        allAdditionalPropertiesDefs5: [],
                        allAdditionalPropertiesDefs6: [],
                        allAdditionalPropertiesDefs7: [],
                        allAdditionalPropertiesDefs8: [],

                        //------------Attachments---------
                        attachmentGenerate_Step1: [],

                        attachmentGenerate_Step2: [],

                        attachmentGenerate_Step3: [],

                        attachmentGenerate_Step4: [],

                        attachmentGenerate_Step5: [],

                        attachmentGenerate_Step6: [],

                        attachmentGenerate_Step7: [],

                    }, () => {
                        this.getAddProperties(country)
                        this.getAttachments('Customer - ' + this.props.type, country)
                        this.getAttachments('Contact', country)
                    })
                }
            }
        }
    }

    checkValidReferralCustomer = async (saveType = 'Partial') => {
        if (this.state.generalbusinessinformation.AffiliateReferredBy == null ||
            this.state.generalbusinessinformation.AffiliateReferredBy === '' ||
            this.state.generalbusinessinformation.AffiliateReferredBy === 0) {
                await this.selectSaveMethod(saveType, true);
        } else {
            const json = await checkValidReferralCustomer(this.state.generalbusinessinformation.AffiliateReferredBy);
            if (json != null && json.validReferralCustomer != null) {
                const validReferralCustomer = json.validReferralCustomer;
                if (validReferralCustomer) { // Valid referral. Don't show referral alert and proceed with normal save flow.
                    await this.selectSaveMethod(saveType, true);
                } else {
                    // Show invalid referral alert. Also, save saveType to return to normal flow after closing alert.
                    this.setState({ showInvalidReferralAlert: true, saveType: saveType });
                }
            }
        }
    };

    selectSaveMethod = async (saveType, validReferral = null) => {
        if (saveType === 'Partial') {
            await this.submitAfterEmailVerification('Partial', validReferral); // normal Partial save flow
        } else if (saveType === 'Full') {
            await this.submit360CorporateClick(); // Show validation email alert
        }
    }

    validateErrors = (event, number) => {
        var objErrors = [
            this.context.errors_Step1.length,
            this.context.errors_Step2.length,
            this.context.errors_Step3.length,
            this.context.errors_Step4.length,
            this.context.errors_Step5.length,
            this.context.errors_Step6.length,
            this.context.errors_Step7.length,
            this.context.errors_Step8.length
        ];

        for (let i = 0; i < number; i++) {
            if (objErrors[i] > 0) {
                this.context.showAlertError((i + 1), true);
                this.context.notFirstLoad((i + 1), false);
                event.preventDefault();
                break;
            }
        }
    }
    validatePartialErrors = async (errors, number) => {
        if (errors.length > 0) {
            this.context.showAlertError((number + 8), true, true);
            this.context.notFirstLoad((number + 8), false, true);
        } else {
            await this.checkValidReferralCustomer('Partial');
            // this.submitAfterEmailVerification('Partial');
            //this.submit360CorporateClick();
        }
    }

    returnDefaultValue = (value) => value == null ? '' : value;

    submitPartial = async () => {
        switch (this.state.targetID) {
            case 'general-business-information-li':
                await this.validatePartialErrors(this.context.errors_Partial_Step1, 1)
                break;
            case 'principal-place-of-business-li':
                await this.validatePartialErrors(this.context.errors_Partial_Step2, 2)
                break;
            // case 'products-and-services-li':
            //     await this.validatePartialErrors(this.context['errors_Partial_Step' + this.compareSteps(3)],this.compareSteps(3))
            //     break;
            // case 'foreign-exchange-forwards-li':
            //     await this.validatePartialErrors(this.context['errors_Partial_Step' + this.compareSteps(4)], this.compareSteps(4))
            //     break;
            case 'account-primary-contact-li':
                await this.validatePartialErrors(this.context['errors_Partial_Step' + this.compareSteps(3)], this.compareSteps(3))
                break;
            case 'directors-li':
                await this.validatePartialErrors(this.context['errors_Partial_Step' + this.compareSteps(4)], this.compareSteps(4))
                break;
            case 'owners-li':
                await this.validatePartialErrors(this.context['errors_Partial_Step' + this.compareSteps(5)], this.compareSteps(5))
                break;
            // case 'directorand-appointed-officer-li':
            //     if (this.state.yesDirector) {
            //         await this.validatePartialErrors(this.context['errors_Partial_Step' + this.compareSteps(6)], this.compareSteps(6))
            //     } else {
            //         await this.validatePartialErrors([], this.compareSteps(6))
            //     }
            //     break;
            // case 'ultimate-beneficial-owner-li':
            //     if (this.state.yesUltimate) {
            //         await this.validatePartialErrors(this.context['errors_Partial_Step' + this.compareSteps(7)], this.compareSteps(7))
            //     } else {
            //         await this.validatePartialErrors([], this.compareSteps(7))
            //     }
            //     break;
            case 'acceptance-of-terms-and-conditions-li':
                await this.validatePartialErrors(this.context['errors_Partial_Step' + this.compareSteps(6)], this.compareSteps(6))
                break;
            default:
                break;
        }
    }
    Submit = async () => {
        await this.checkValidReferralCustomer(); // Check referral before saving;
        // this.submit360CorporateClick();
    }
    submit360CorporateClick = async () => {
        let validatedEmail = false;
        this.setState({ loading: true });
        const json = await getCustomerInfoByID(this.state.customer.CustomerId);
        if (json != null) {
            json.CustomerProperties.forEach(element => {
                if (element.name === 'EmailVerified') {
                    // debugger;
                    this.setState({emailVerified:element.value});
                    switch (element.value) {
                        default:
                        case 'NO':
                            validatedEmail = false;
                            break;
                        case 'YES':
                            validatedEmail = true;
                            break;
                    }
                }
            });
        }
        this.setState({ loading: false });

        if (validatedEmail) {
            // await this.submitAfterEmailVerification();
            this.setState({ showFinalSubmitAlert: true });
        } else {
            this.setState({
                emailVerifiedSubmit: true
            });

          /*  const parameterResponse = await getParameterByName('DisableSendEmailRegform');
            // skip "verify your email" popup if global is true
            if (parameterResponse != null && parameterResponse.ParameterValue !== '' && parameterResponse.ParameterValue.toLowerCase() === 'true') {
                // await this.submitAfterEmailVerification();
                this.setState({ showFinalSubmitAlert: true });
            } else {
                this.setState({
                    emailVerifiedSubmit: true
                });
            }*/
        }
    }

    closeFinalSubmitAlert = () => {
        this.setState({ showFinalSubmitAlert: false });
    };

    downloadPDF = async () => {
        this.setState({ showFinalSubmitAlert: false });
        await getPDF('Corporate', {
            Generalbusinessinformation: this.state.generalbusinessinformation,
            PrincipalPlaceOfBusiness: this.state.principalplaceofbusiness,
            Accountprimarycontact: this.state.accountprimarycontact,
            DirectorAndAppointedOfficer: this.state.directorandappointedofficer,
            UltimateBeneficialOwner: this.state.ultimatebeneficialowner,
            Owners: this.state.owners,
            AdditionalProperties: {
                step1: this.state.valuesOfAdditionalPropertiesDefs1,
                step2: this.state.valuesOfAdditionalPropertiesDefs2,
                step3: this.state.valuesOfAdditionalPropertiesDefs3,
                step4: this.state.valuesOfAdditionalPropertiesDefs4,
                step5: this.state.valuesOfAdditionalPropertiesDefs5,
                step6: this.state.valuesOfAdditionalPropertiesDefs6,
                step7: this.state.valuesOfAdditionalPropertiesDefs7
            }
        });
        await this.submitAfterEmailVerification();
    };

    continueWithFinalSubmit = async () => {
        this.setState({ showFinalSubmitAlert: false });
        await this.submitAfterEmailVerification();
    };

    submitAfterEmailVerification = async (status, validReferral = null) => {
        /*
            acceptanceoftermsandconditions
        */
        let ContactDocuments = [];
        /*if (this.state.TaskAttachments_Step6 != null) {
            if (this.state.TaskAttachments_Step6.documentFiles.length > 0) {
                ContactDocuments = this.contactDoc(this.state.TaskAttachments_Step6, 0)
            }
        }*/
        if(this.state.directorandappointedofficer != null && this.state.directorandappointedofficer.documentTypeID !== '')
        {
            ContactDocuments = this.getContactTaskDoc(this.state.directorandappointedofficer,0);
        }
        if(this.state.ultimatebeneficialowner != null && this.state.ultimatebeneficialowner.documentTypeID != '')
        {
            ContactDocuments = this.getContactTaskDoc(this.state.ultimatebeneficialowner,1);
        }
        /*if (this.state.TaskAttachments_Step7 != null) {
            if (this.state.TaskAttachments_Step7.documentFiles.length > 0) {
                ContactDocuments = this.contactDoc(this.state.TaskAttachments_Step7, 1)
            }
        }*/
        let model = {
            TypeSave: status != null ? status : (this.state.CustomerAgreement != null ? this.state.CustomerAgreement.isComplete ? 'Complete' : 'Incomplete' : 'Incomplete'),
            //TypeSave: status != null ? status : (this.state.acceptanceoftermsandconditions != null ? this.state.acceptanceoftermsandconditions.customerAgreement ? 'Complete' : 'Incomplete' : 'Incomplete'),
            FormToken: "",
            PdfFileToken: "",
            FormTimeStamp: "",
            FormStatusAction: "",
            TypeCustomer: this.props.type,

            CustomerID: this.state.customer.CustomerId,
            CustomerUpdateToken: this.state.customer.UpdateToken,

            AddressID: this.state.objGrincipalplaceofbusiness.AddressId,
            AddressUpdateToken: this.state.objGrincipalplaceofbusiness.AddressUpdateToken,

            ContactID: this.state.accountprimarycontact.ContactId,
            ContactAddressID: this.state.accountprimarycontact.ContactAddressId,
            ContactAddressUpdateToken: this.state.accountprimarycontact.ContactAddressUpdateToken,

            PreferredLanguage: sessionStorage.getItem('360Language'),
            IsTradingAddressEqualTo: "No",

            //-------generalbusinessinformation
            CompanyName: this.state.generalbusinessinformation.companyName,
            CompanyStructure: '',
            CompanyRegNumber: '',
            DoingBusinessAs: '',
            //BusinessWebsite: '',
            DateOfFormation: '',
            //CountryOfIncorporation: '',
            //StateRegionOfIncorporation: '',
            NAICS: '',
            FederalTaxId: '',
            //BusinessTelephoneNo: '',
            //CompanyStructure: this.state.generalbusinessinformation.companytypeID,
            //CompanyRegNumber: this.state.generalbusinessinformation.registrationNumber,
            //DoingBusinessAs: this.state.generalbusinessinformation.doingBusinessAs,
            BusinessWebsite: this.state.generalbusinessinformation.website,
            IndustrySector: this.state.generalbusinessinformation.IndustrySector,
            //DateOfFormation: this.state.generalbusinessinformation.DateOfBirth,
            CountryOfIncorporation: this.state.generalbusinessinformation.countryID,
            StateRegionOfIncorporation: this.state.generalbusinessinformation.OtherState !== '' ? this.state.generalbusinessinformation.OtherState : this.state.generalbusinessinformation.stateID,
            //NAICS: this.state.generalbusinessinformation.naicsID,
            //FederalTaxId: this.state.generalbusinessinformation.FederalTaxID,
            BusinessTelephoneNo: this.state.generalbusinessinformation.ResidentialPhoneNumber,
            ReferralID: validReferral ? this.state.generalbusinessinformation.AffiliateReferredBy : null,

            //-------principalplaceofbusiness
            CompanyAddressStreet: this.state.principalplaceofbusiness.BusinessAddressStreet,
            CompanyAddressLine2: this.state.principalplaceofbusiness.BusinessAddressLine2,
            CompanyAddressCountry: this.state.principalplaceofbusiness.BusinessAddressStreet !== '' ? this.state.principalplaceofbusiness.countryID : '',
            CompanyAddressStateName: this.state.principalplaceofbusiness.OtherState !== '' ? this.state.principalplaceofbusiness.OtherState : this.state.principalplaceofbusiness.BusinessAddressStateName,
            CompanyAddressStateId: this.state.principalplaceofbusiness.BusinessAddressStateId != null ? this.state.principalplaceofbusiness.BusinessAddressStateId : null,
            CompanyAddressCity: this.state.principalplaceofbusiness.BusinessAddressCityID,
            CompanyAddressPostalCode: this.state.principalplaceofbusiness.BusinessAddressPostalCode,

            //-------productsandservices
            DescriptionOfBusiness: '',
            ExpectedMonthlyPayments: '',
            ExpectedMonthlyVolume: '',
            TradingCountries: '',
            //-------foreignexchangeforwards
            LEINumber: '',
            LEIExpirationDate: '',


            //-------not tab
            CorrespondenceCountry: "",
            CorrespondenceAddress: "",
            CorrespondenceCity: "",
            CorrespondenceState: "",
            CorrespondenceZip: "",
            TickerSymbol: "",
            Description: "",

            //-------accountprimarycontact
            
            // For Xushi: don't send this property to reduce json size
            list_mainContacts: getCurrentCompanyName() !== 'Xushi' ? this.mainContacts(this.state.accountprimarycontact) : null,
            // Xushi: send contacts instead because API uses a different name than list_mainContacts
            contacts: this.mainContacts(this.state.accountprimarycontact),

            //-------not tab
            AccountPrimaryContact: "",
            AFEXDirectUserRole: "",

            CurrenciesInterestedTrading: "",
            CurrenciesInterestedTradingOther: "",
            AmountTradedMonth: "",
            PurposeExchangingCurrency: "",
            PurposeExchangingCurrencyOther: "",
            WhereYouHearAboutUs: "",
            WhereYouHearAboutUsSalesCall: "",
            WhereYouHearAboutUsReferral: "",
            WhereYouHearAboutUsEvent: "",
            WhereYouHearAboutUsOther: "",


            //----Contacts
            HasTraders: true,
            ShareHolder01_ContactID: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ContactId01 : '',
            ShareHolder01_ContactAddressID: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ContactAddressId01 : '',
            ShareHolder01_ContactAddressUpdateToken: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ContactAddressUpdateToken01 : '',

            //-------directorandappointedofficer
            FirstNameShareholderOwner01: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.FirstName01 : '',
            MiddleNameShareholderOwner01: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ShortName01 : '',
            LastNameShareholderOwner01: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.LastName01 : '',
            DateOfBirthShareholderOwner01: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.DateOfBirth01 : '',
            ResidentialAddressStreetShareholderOwner01: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressStreet01 : '',
            ResidentialAddressLine2ShareholderOwner01: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressLine201 : '',
            ResidentialAddressCountryShareholderOwner01: this.state.directorandappointedofficer != null && this.state.directorandappointedofficer.ResidentialAddressStreet01 !== '' ? this.state.directorandappointedofficer.countryID01 : '',
            ResidentialAddressStateNameShareholderOwner01: this.state.directorandappointedofficer != null ? (this.state.directorandappointedofficer.OtherState01 !== '' ? this.state.directorandappointedofficer.OtherState01 : this.state.directorandappointedofficer.ResidentialAddressStateName01) : '',
            ResidentialAddressStateIdShareholderOwner01: this.state.directorandappointedofficer != null && this.state.directorandappointedofficer.ResidentialAddressStateId01 !== '' ? parseInt(this.state.directorandappointedofficer.ResidentialAddressStateId01) : null,
            ResidentialAddressCityShareholderOwner01: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressCity01 : '',
            ResidentialAddressPostalCodeShareholderOwner01: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressPostalCode01 : '',
            ResidentialPhoneNumberShareholderOwner01: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialPhoneNumber01 : '',
            MobileNumberShareholderOwner01: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.MobileNumber01 : '',
            EmailAddressShareholderOwner01: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.EmailAddress01 : '',
            PoliticallyExposedPersonShareholderOwner01: false,
            IndividualRolesShareholderOwner01: [],
            PercentOwnershipShareholderOwner01: '',
            //CitizenshipShareholderOwner01: '',
            JobTitleShareholderOwner01: '',
            JobTitleOtherShareholderOwner01: '',
            SSNShareholderOwner01: '',
            IdTypeShareholderOwner01: '',
            IdNoShareholderOwner01: '',
            IdExpirationDateShareholderOwner01: '',
            IssueJurisdictionShareholderOwner01: '',
            AccountPrimaryContactShareholderOwner01: '',
            AFEXDirectUserRoleShareholderOwner01: '',
            CountryIDIdentificationShareholderOwner01: '',
            StateIDIdentificationShareholderOwner01: '',
            StateNameIdentificationShareholderOwner01: '',
            ShareHolder01_AdditionalProperties: this.transformAddProperties('Contact01') != null ? this.transformAddProperties('Contact01') : [], //EXTENDED CONTACT01
            //IndividualRolesShareholderOwner01: [this.state.directorandappointedofficer.individualRolesID],
            //PercentOwnershipShareholderOwner01: this.state.directorandappointedofficer.PercentOwnership,
            CitizenshipShareholderOwner01: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.CitizenshipID : '',
            //JobTitleShareholderOwner01: this.state.directorandappointedofficer.jobTitlesID,
            //JobTitleOtherShareholderOwner01: this.state.directorandappointedofficer.jobTitlesID,
            //SSNShareholderOwner01: this.state.directorandappointedofficer.SSN,
            //IdTypeShareholderOwner01: this.state.directorandappointedofficer.IDType,
            //IdNoShareholderOwner01: this.state.directorandappointedofficer.IDNo,
            //IdExpirationDateShareholderOwner01: this.state.directorandappointedofficer.IDExpirationDate,
            //IssueJurisdictionShareholderOwner01: this.state.directorandappointedofficer.IssueJurisdiction,
            //AccountPrimaryContactShareholderOwner01: "",
            //AFEXDirectUserRoleShareholderOwner01: this.state.directorandappointedofficer.clickRadio,
            //CountryIDIdentificationShareholderOwner01: this.state.directorandappointedofficer.countryIDIdentification,
            //StateIDIdentificationShareholderOwner01: this.state.directorandappointedofficer.IDType === 'DriversLicense' ? (this.state.directorandappointedofficer.OtherStateIdentification !== '' ? null : parseInt(this.state.directorandappointedofficer.StateIdIdentification)) : '',
            //StateNameIdentificationShareholderOwner01: this.state.directorandappointedofficer.IDType === 'DriversLicense' ? (this.state.directorandappointedofficer.OtherStateIdentification !== '' ? this.state.directorandappointedofficer.OtherStateIdentification : this.state.directorandappointedofficer.StateNameIdentification) : '',
            ShareHolder01_list_Properties: [],
            ShareHolder01_TypeOfContact: this.state.directorandappointedofficer.Roles01 != null ? this.state.directorandappointedofficer.Roles01 : [],

            //-------ultimatebeneficialowner
            ShareHolder02_ContactID: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ContactId02 : '',
            ShareHolder02_ContactAddressID: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ContactAddressId02 : '',
            ShareHolder02_ContactAddressUpdateToken: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ContactAddressUpdateToken02 : '',

            FirstNameShareholderOwner02: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.FirstName02 : '',
            MiddleNameShareholderOwner02: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ShortName02 : '',
            LastNameShareholderOwner02: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.LastName02 : '',
            DateOfBirthShareholderOwner02: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.DateOfBirth02 : '',
            ResidentialAddressStreetShareholderOwner02: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressStreet02 : '',
            ResidentialAddressLine2ShareholderOwner02: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressLine202 : '',
            ResidentialAddressCountryShareholderOwner02: this.state.directorandappointedofficer != null && this.state.directorandappointedofficer.ResidentialAddressStreet02 !== '' ? this.state.directorandappointedofficer.countryID02 : '',
            ResidentialAddressStateNameShareholderOwner02: this.state.directorandappointedofficer != null ? (this.state.directorandappointedofficer.OtherState02 !== '' ? this.state.directorandappointedofficer.OtherState02 : this.state.directorandappointedofficer.ResidentialAddressStateName02) : '',
            ResidentialAddressStateIdShareholderOwner02: this.state.directorandappointedofficer != null && this.state.directorandappointedofficer.ResidentialAddressStateId02 !== '' ? parseInt(this.state.directorandappointedofficer.ResidentialAddressStateId02) : null,
            ResidentialAddressCityShareholderOwner02: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressCity02 : '',
            ResidentialAddressPostalCodeShareholderOwner02: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressPostalCode02 : '',
            ResidentialPhoneNumberShareholderOwner02: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialPhoneNumber02 : '',
            MobileNumberShareholderOwner02: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.MobileNumber02 : '',
            EmailAddressShareholderOwner02: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.EmailAddress02 : '',
            PoliticallyExposedPersonShareholderOwner02: true,

            IndividualRolesShareholderOwner02: [],
            PercentOwnershipShareholderOwner02: '',
            //CitizenshipShareholderOwner02: '',
            JobTitleShareholderOwner02: '',
            JobTitleOtherShareholderOwner02: '',
            SSNShareholderOwner02: '',
            IdTypeShareholderOwner02: '',
            IdNoShareholderOwner02: '',
            IdExpirationDateShareholderOwner02: '',
            IssueJurisdictionShareholderOwner02: '',
            AccountPrimaryContactShareholderOwner02: '',
            AFEXDirectUserRoleShareholderOwner02: '',
            CountryIDIdentificationShareholderOwner02: '',
            StateIDIdentificationShareholderOwner02: '',
            StateNameIdentificationShareholderOwner02: '',
            ShareHolder02_AdditionalProperties: this.transformAddProperties('Contact02') != null ? this.transformAddProperties('Contact02') : [], //EXTENDED CONTACT02
            //IndividualRolesShareholderOwner02: [this.state.ultimatebeneficialowner.individualRolesID],
            //PercentOwnershipShareholderOwner02: this.state.ultimatebeneficialowner.PercentOwnership,
            CitizenshipShareholderOwner02: this.state.ultimatebeneficialowner != null ? this.state.ultimatebeneficialowner.CitizenshipID : '',
            //JobTitleShareholderOwner02: this.state.ultimatebeneficialowner.jobTitlesID,
            //JobTitleOtherShareholderOwner02: this.state.ultimatebeneficialowner.jobTitlesID,
            //SSNShareholderOwner02: this.state.ultimatebeneficialowner.SSN,
            //IdTypeShareholderOwner02: this.state.ultimatebeneficialowner.IDType,
            //IdNoShareholderOwner02: this.state.ultimatebeneficialowner.IDNo,
            //IdExpirationDateShareholderOwner02: this.state.ultimatebeneficialowner.IDExpirationDate,
            //IssueJurisdictionShareholderOwner02: this.state.ultimatebeneficialowner.IssueJurisdiction,
            //AccountPrimaryContactShareholderOwner02: "",
            //AFEXDirectUserRoleShareholderOwner02: this.state.ultimatebeneficialowner.clickRadio,
            //CountryIDIdentificationShareholderOwner02: this.state.ultimatebeneficialowner.countryIDIdentification,
            //StateIDIdentificationShareholderOwner02: this.state.ultimatebeneficialowner.IDType === 'DriversLicense' ? (this.state.ultimatebeneficialowner.OtherStateIdentification !== '' ? null : parseInt(this.state.ultimatebeneficialowner.StateIdIdentification)) : '',
            //StateNameIdentificationShareholderOwner02: this.state.ultimatebeneficialowner.IDType === 'DriversLicense' ? (this.state.ultimatebeneficialowner.OtherStateIdentification !== '' ? this.state.ultimatebeneficialowner.OtherStateIdentification : this.state.ultimatebeneficialowner.StateNameIdentification) : '',
            ShareHolder02_list_Properties: [],
            ShareHolder02_TypeOfContact: this.state.directorandappointedofficer.Roles02 != null ? this.state.directorandappointedofficer.Roles02 : [],

            ShareHolder03_ContactID: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ContactId03 : '',
            ShareHolder03_ContactAddressID: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ContactAddressId03 : '',
            ShareHolder03_ContactAddressUpdateToken: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ContactAddressUpdateToken03 : '',

            FirstNameShareholderOwner03: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.FirstName03 : '',
            MiddleNameShareholderOwner03: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ShortName03 : '',
            LastNameShareholderOwner03: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.LastName03 : '',
            DateOfBirthShareholderOwner03: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.DateOfBirth03 : '',
            ResidentialAddressStreetShareholderOwner03: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressStreet03 : '',
            ResidentialAddressLine2ShareholderOwner03: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressLine203 : '',
            ResidentialAddressCountryShareholderOwner03: this.state.directorandappointedofficer != null && this.state.directorandappointedofficer.ResidentialAddressStreet03 !== '' ? this.state.directorandappointedofficer.countryID03 : '',
            ResidentialAddressStateNameShareholderOwner03: this.state.directorandappointedofficer != null ? (this.state.directorandappointedofficer.OtherState03 !== '' ? this.state.directorandappointedofficer.OtherState03 : this.state.directorandappointedofficer.ResidentialAddressStateName03) : '',
            ResidentialAddressStateIdShareholderOwner03: this.state.directorandappointedofficer != null && this.state.directorandappointedofficer.ResidentialAddressStateId03 !== '' ? parseInt(this.state.directorandappointedofficer.ResidentialAddressStateId03) : null,
            ResidentialAddressCityShareholderOwner03: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressCity03 : '',
            ResidentialAddressPostalCodeShareholderOwner03: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressPostalCode03 : '',
            ResidentialPhoneNumberShareholderOwner03: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialPhoneNumber03 : '',
            MobileNumberShareholderOwner03: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.MobileNumber03 : '',
            EmailAddressShareholderOwner03: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.EmailAddress03 : '',
            PoliticallyExposedPersonShareholderOwner03: true,
            IndividualRolesShareholderOwner03: [],
            PercentOwnershipShareholderOwner03: "",
            JobTitleShareholderOwner03: "",
            JobTitleOtherShareholderOwner03: "",
            SSNShareholderOwner03: "",
            IdTypeShareholderOwner03: "",
            IdNoShareholderOwner03: "",
            IdExpirationDateShareholderOwner03: "",
            IssueJurisdictionShareholderOwner03: "",
            AccountPrimaryContactShareholderOwner03: "",
            AFEXDirectUserRoleShareholderOwner03: "",
            CountryIDIdentificationShareholderOwner03: "",
            StateIDIdentificationShareholderOwner03: "",
            StateNameIdentificationShareholderOwner03: "",
            ShareHolder03_AdditionalProperties: [],
            ShareHolder03_list_Properties:[],
            ShareHolder03_TypeOfContact: this.state.directorandappointedofficer.Roles03 != null ? this.state.directorandappointedofficer.Roles03 : [],

            ShareHolder04_ContactID: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ContactId04 : '',
            ShareHolder04_ContactAddressID: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ContactAddressId04 : '',
            ShareHolder04_ContactAddressUpdateToken: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ContactAddressUpdateToken04 : '',

            FirstNameShareholderOwner04: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.FirstName04 : '',
            MiddleNameShareholderOwner04: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ShortName04 : '',
            LastNameShareholderOwner04: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.LastName04 : '',
            DateOfBirthShareholderOwner04: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.DateOfBirth04 : '',
            ResidentialAddressStreetShareholderOwner04: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressStreet04 : '',
            ResidentialAddressLine2ShareholderOwner04: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressLine204 : '',
            ResidentialAddressCountryShareholderOwner04: this.state.directorandappointedofficer != null && this.state.directorandappointedofficer.ResidentialAddressStreet04 !== '' ? this.state.directorandappointedofficer.countryID04 : '',
            ResidentialAddressStateNameShareholderOwner04: this.state.directorandappointedofficer != null ? (this.state.directorandappointedofficer.OtherState04 !== '' ? this.state.directorandappointedofficer.OtherState04 : this.state.directorandappointedofficer.ResidentialAddressStateName04) : '',
            ResidentialAddressStateIdShareholderOwner04: this.state.directorandappointedofficer != null && this.state.directorandappointedofficer.ResidentialAddressStateId04 !== '' ? parseInt(this.state.directorandappointedofficer.ResidentialAddressStateId04) : null,
            ResidentialAddressCityShareholderOwner04: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressCity04 : '',
            ResidentialAddressPostalCodeShareholderOwner04: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressPostalCode04 : '',
            ResidentialPhoneNumberShareholderOwner04: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialPhoneNumber04 : '',
            MobileNumberShareholderOwner04: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.MobileNumber04 : '',
            EmailAddressShareholderOwner04: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.EmailAddress04 : '',
            PoliticallyExposedPersonShareholderOwner04: true,
            IndividualRolesShareholderOwner04: [],
            PercentOwnershipShareholderOwner04: "",
            JobTitleShareholderOwner04: "",
            JobTitleOtherShareholderOwner04: "",
            SSNShareholderOwner04: "",
            IdTypeShareholderOwner04: "",
            IdNoShareholderOwner04: "",
            IdExpirationDateShareholderOwner04: "",
            IssueJurisdictionShareholderOwner04: "",
            AccountPrimaryContactShareholderOwner04: "",
            AFEXDirectUserRoleShareholderOwner04: "",
            CountryIDIdentificationShareholderOwner04: "",
            StateIDIdentificationShareholderOwner04: "",
            StateNameIdentificationShareholderOwner04: "",
            ShareHolder04_AdditionalProperties: [],
            ShareHolder04_list_Properties:[],
            ShareHolder04_TypeOfContact: this.state.directorandappointedofficer.Roles04 != null ? this.state.directorandappointedofficer.Roles04 : [],

            ShareHolder05_ContactID: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ContactId05 : '',
            ShareHolder05_ContactAddressID: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ContactAddressId05 : '',
            ShareHolder05_ContactAddressUpdateToken: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ContactAddressUpdateToken05 : '',

            FirstNameShareholderOwner05: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.FirstName05 : '',
            MiddleNameShareholderOwner05: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ShortName05 : '',
            LastNameShareholderOwner05: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.LastName05 : '',
            DateOfBirthShareholderOwner05: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.DateOfBirth05 : '',
            ResidentialAddressStreetShareholderOwner05: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressStreet05 : '',
            ResidentialAddressLine2ShareholderOwner05: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressLine205 : '',
            ResidentialAddressCountryShareholderOwner05: this.state.directorandappointedofficer != null && this.state.directorandappointedofficer.ResidentialAddressStreet05 !== '' ? this.state.directorandappointedofficer.countryID05 : '',
            ResidentialAddressStateNameShareholderOwner05: this.state.directorandappointedofficer != null ? (this.state.directorandappointedofficer.OtherState05 !== '' ? this.state.directorandappointedofficer.OtherState05 : this.state.directorandappointedofficer.ResidentialAddressStateName05) : '',
            ResidentialAddressStateIdShareholderOwner05: this.state.directorandappointedofficer != null && this.state.directorandappointedofficer.ResidentialAddressStateId05 !== '' ? parseInt(this.state.directorandappointedofficer.ResidentialAddressStateId05) : null,
            ResidentialAddressCityShareholderOwner05: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressCity05 : '',
            ResidentialAddressPostalCodeShareholderOwner05: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialAddressPostalCode05 : '',
            ResidentialPhoneNumberShareholderOwner05: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.ResidentialPhoneNumber05 : '',
            MobileNumberShareholderOwner05: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.MobileNumber05 : '',
            EmailAddressShareholderOwner05: this.state.directorandappointedofficer != null ? this.state.directorandappointedofficer.EmailAddress05 : '',
            PoliticallyExposedPersonShareholderOwner05: true,
            IndividualRolesShareholderOwner05: [],
            PercentOwnershipShareholderOwner05: "",
            JobTitleShareholderOwner05: "",
            JobTitleOtherShareholderOwner05: "",
            SSNShareholderOwner05: "",
            IdTypeShareholderOwner05: "",
            IdNoShareholderOwner05: "",
            IdExpirationDateShareholderOwner05: "",
            IssueJurisdictionShareholderOwner05: "",
            AccountPrimaryContactShareholderOwner05: "",
            AFEXDirectUserRoleShareholderOwner05: "",
            CountryIDIdentificationShareholderOwner05: "",
            StateIDIdentificationShareholderOwner05: "",
            StateNameIdentificationShareholderOwner05: "",
            ShareHolder05_AdditionalProperties: [],
            ShareHolder05_list_Properties:[],
            ShareHolder05_TypeOfContact: this.state.directorandappointedofficer.Roles05 != null ? this.state.directorandappointedofficer.Roles05 : [],
            
            //personal identification
            ShareHolder01_list_Documents_Task : this.AccountDoc(this.state.directorandappointedofficer, 0),
            ShareHolder02_list_Documents_Task : this.AccountDoc(this.state.directorandappointedofficer, 1),
            ShareHolder03_list_Documents_Task : this.AccountDoc(this.state.directorandappointedofficer, 2),
            ShareHolder04_list_Documents_Task : this.AccountDoc(this.state.directorandappointedofficer, 3),
            ShareHolder05_list_Documents_Task : this.AccountDoc(this.state.directorandappointedofficer, 4),

            //CustomerAgreement
            Terms: this.state.CustomerAgreement != null ? (this.state.CustomerAgreement.customerAgreement ? 'Yes' : 'No') : 'No',
            DocumentForm: this.state.CustomerAgreement != null ? this.state.CustomerAgreement.accountFile : '',
            DocumentForm_FileName: this.state.CustomerAgreement != null ? this.state.CustomerAgreement.accountFileName : '',

            //Terms: this.state.acceptanceoftermsandconditions != null ? (this.state.acceptanceoftermsandconditions.customerAgreement ? 'Yes' : 'No') : 'No',
            AccountNumber: "",

            //DocumentForm: "AFEX",
            //DocumentForm_FileName: "AFEX",
            AdditionalProperties: this.transformAddProperties('Corporate') != null ? this.transformAddProperties('Corporate') : [],
            AdditionalAttachments: this.state.TaskAttachments_Step1 != null ? this.state.TaskAttachments_Step1.documentFiles : [],

            //--------ATTACHMENTS----------
            CustomerDocuments: this.state.TaskAttachments_Step5 != null ? this.state.TaskAttachments_Step5.documentFiles.length > 0 ? this.customerDoc(this.state.TaskAttachments_Step5) : [] : [],
            ContactDocuments,
            RegForm_Origin: "",
        }
        console.log(model)
        this.setState({ loading: true });
        const jsonResponse = await submit360CorpForm(model);
        this.setState({ loading: false });
        switch (jsonResponse.httpStatusCode) {
            case 200:
                if (jsonResponse.Response) {
                    const parameterResponse = await getParameterByName('DisableSendEmailRegform');
                    const disableSendEmailRegform = parameterResponse != null && parameterResponse.ParameterValue != null && parameterResponse.ParameterValue.toLowerCase() === 'true';
                    if (model.TypeSave === 'Complete') {
                        // if parameter doesn't exists or is false, send email to customer
                        if (!disableSendEmailRegform) {
                            sendCustomerRegistrationEmail(this.state.accountprimarycontact.EmailAddress01, this.state.customer.CustomerId, true).then((response) => console.log(response));
                        }
                        // false = Compliance
                        sendCustomerRegistrationEmail(this.state.accountprimarycontact.EmailAddress01, this.state.customer.CustomerId, false).then((response) => console.log(response));
                    } else if (model.TypeSave === 'Partial') {
                        sendCompleteRegistrationEmail(this.state.accountprimarycontact.EmailAddress01, this.state.customer.CustomerId).then((response) => console.log(response));
                    }
                    // Try to save Owner
                    const _owners = this.owners(this.state.owners);
                    console.log(_owners);
                    if (_owners.length > 0) {
                        const ownerSaveResponse = await saveOwners({ owners: _owners});
                        if (ownerSaveResponse.httpStatusCode === 200) {
                            if (model.TypeSave === 'Complete' && (this.state.emailVerifiedSubmit || this.state.emailVerified === 'YES')) {// || disableSendEmailRegform)) {
                                sessionStorage.setItem('CustomerStatus', 'Complete');
                                this.setState({ showSuccessAlert: true, alertTitle: 'Success', alertMessage: 'Thanks for submitting your application. We will contact you shortly.' });
                            } else {
                                sessionStorage.setItem('CustomerStatus', 'Incomplete');
                                if (model.TypeSave === 'Partial') {
                                    sessionStorage.setItem('CustomerStatus', 'Partial');
                                }
                                this.setState({ showSuccessAlert: true, alertTitle: 'Success', alertMessage: 'You will be able to return to our secured site and complete your application at a later date.' });
                            }
                        } else {
                            sessionStorage.setItem('CustomerStatus', 'Partial');
                            // this.context.showAlert('Error', 'An error ocurred while trying to save your Owner information Corporate Account Registration. Please, try again.')
                            this.setState({ showSuccessAlert: true, alertTitle: 'Warning', alertMessage: 'There was a problem while saving the owners section. Please try again later.' });
                        }
                    } else {
                        if (model.TypeSave === 'Complete' && (this.state.emailVerifiedSubmit || this.state.emailVerified === 'YES')) {// || disableSendEmailRegform)) {
                            sessionStorage.setItem('CustomerStatus', 'Complete');
                            this.setState({ showSuccessAlert: true, alertTitle: 'Success', alertMessage: 'Thanks for submitting your application. We will contact you shortly.' });
                        } else {
                            sessionStorage.setItem('CustomerStatus', 'Incomplete');
                            if (model.TypeSave === 'Partial') {
                                sessionStorage.setItem('CustomerStatus', 'Partial');
                            }
                            this.setState({ showSuccessAlert: true, alertTitle: 'Success', alertMessage: 'You will be able to return to our secured site and complete your application at a later date.' });
                        }
                    }
                } else {
                    this.context.showAlert('Error', 'An error ocurred while sending Corporate Account Registration. Please, try again.')
                }
                break;
            case 503:
                this.context.showAlert('Error', 'An error ocurred while sending Corporate Account Registration. Please, try again.');
                break;
            default:
                this.context.showAlert('Error', jsonResponse.Message);
                break;
        }
    }
    mainContacts = (obj) => {
        let helper = [];
        const maxNum = [obj.trader01,
                        obj.trader02,
                        obj.trader03,
                        obj.trader04,
                        obj.trader05];
        maxNum.forEach((element, index) => {
            let i = index + 1;
            if (element) {
                let model = {};
                model.TypeOfContact = obj['Roles0' + i] != null ? obj['Roles0' + i] : [];
                model.ContactID = obj['ContactId0' + i] != null ? obj['ContactId0' + i] : null;
                model.ContactAddressID = obj['ContactAddressId0' + i] != null ? obj['ContactAddressId0' + i] : null;
                model.ContactAddressUpdateToken = obj['ContactAddressUpdateToken0' + i] != null ? obj['ContactAddressUpdateToken0' + i] : null;
                model.Salutation = obj['SalutationID0' + i] != null ? obj['SalutationID0' + i] : '';
                model.FirstName = obj['FirstName0' + i];
                model.ShortName = obj['ShortName0' + i];
                model.MiddleName = obj['MiddleName0' + i];
                model.LastName = obj['LastName0' + i];
                model.Gender = '';
                model.DateOfBirth = obj['DateOfBirth0' + i];
                model.ResidentialAddressStreet = obj['ResidentialAddressStreet0' + i];
                model.ResidentialAddressLine2 = obj['ResidentialAddressLine20' + i];
                model.ResidentialAddressCountry = obj['countryID0' + i];
                model.ResidentialAddressStateName = obj['OtherState0' + i] !== '' ? obj['OtherState0' + i] : obj['ResidentialAddressStateName0' + i];
                model.ResidentialAddressStateId = obj['OtherState0' + i] !== '' ? null : parseInt(obj['ResidentialAddressStateId0' + i]);
                model.ResidentialAddressCity = obj['ResidentialAddressCity0' + i];
                model.ResidentialAddressPostalCode = obj['ResidentialAddressPostalCode0' + i];
                model.ResidentialPhoneNumber = obj['ResidentialPhoneNumber0' + i];
                model.MobileNumber = obj['MobileNumber0' + i];
                model.EmailAddress = obj['EmailAddress0' + i];
                // model.PoliticallyExposedPerson = obj['clickRadio0' + i] !== 'Yes' ? false : true;
                model.PoliticallyExposedPerson = obj['PEP0' + i] !== null ? obj['PEP0' + i] : true;
                model.EasyPayAuthorised = obj['EasyPayAuthorised0' + i] !== null ? obj['EasyPayAuthorised0' + i] : false;
                //-----AFEX---------
                model.IndividualRoles = [];
                model.PercentOwnership = '';
                model.JobTitle = '';
                model.JobTitleOther = '';
                model.SSN = '';
                model.IdType = '';
                model.IdNo = '';
                model.IdExpirationDate = '';
                model.IssueJurisdiction = '';
                model.AccountPrimaryContact = '';
                model.AFEXDirectUserRole = '';

                model.Citizenship = obj['CitizenshipID0' + i];
                model.CountryIDIdentification = '';
                model.StateIDIdentification = '';
                model.StateNameIdentification = '';
                //-----AFEX---------
                model.list_Properties = [
                    {
                        Description: 'AFEX Roles',
                        Value: 'PRIMARY'
                    }
                ];
                model.list_AdditionalProperties = this.transformAddProperties('Contact Principal', obj, i) != null ? this.transformAddProperties('Contact Principal', obj, i) : [];//EXTENDED PRINCIPAL;
                model.list_Documents_Task = this.AccountDoc(obj, i - 1);
                helper.push(model);
            }
        })
        return helper;
    }

    owners = (obj) => {
        let helper = [];
        const maxNum = [obj?.trader01 ?? false,
                        obj?.trader02 ?? false,
                        obj?.trader03 ?? false,
                        obj?.trader04 ?? false,
                        obj?.trader05 ?? false];
        maxNum.forEach((element, index) => {
            let i = index + 1;
            if (element && obj['FirstName0' + i] != null && obj['FirstName0' + i] !== '') {
                let model = {};
                model.customerId = this.state.customer.CustomerId;
                model.status = obj['Status0' + i] ?? '';
                model.ownerId = obj['OwnerId0' + i];
                model.type = obj['OwnerType0' + i] ?? 'Individual';
                // model.TypeOfContact = obj['Roles0' + i] != null ? obj['Roles0' + i] : [];
                // model.ContactID = obj['ContactId0' + i] != null ? obj['ContactId0' + i] : null;
                // model.ContactAddressID = obj['ContactAddressId0' + i] != null ? obj['ContactAddressId0' + i] : null;
                // model.ContactAddressUpdateToken = obj['ContactAddressUpdateToken0' + i] != null ? obj['ContactAddressUpdateToken0' + i] : null;
                model.Salutation = obj['SalutationID0' + i] != null ? obj['SalutationID0' + i] : '';
                model.FirstName = obj['FirstName0' + i];
                model.ShortName = obj['ShortName0' + i];
                model.MiddleName = obj['MiddleName0' + i];
                model.LastName = obj['LastName0' + i];
                model.Gender = '';
                model.date_of_birth = obj['DateOfBirth0' + i];
                model.PercentageOwnership = obj['PercentageOwnership0' + i] ?? '';
                model.PhoneNumber = obj['ResidentialPhoneNumber0' + i];
                model.MobilePhone = obj['MobileNumber0' + i];
                model.Email = obj['EmailAddress0' + i];
                // model.list_AdditionalProperties = this.transformAddProperties('Contact Principal', obj, i) != null ? this.transformAddProperties('Contact Principal', obj, i) : [];//EXTENDED PRINCIPAL;
                 model.list_Documents_Task = this.AccountDoc(obj, i - 1);
                model.addresses = [{
                    AddressValue : obj['ResidentialAddressStreet0' + i],
                    Address2 : obj['ResidentialAddressLine20' + i],
                    CountryID : obj['countryID0' + i],
                    OtherState : obj['OtherState0' + i] !== '' ? obj['OtherState0' + i] : obj['ResidentialAddressStateName0' + i],
                    StateID : obj['OtherState0' + i] !== '' ? null : parseInt(obj['ResidentialAddressStateId0' + i]),
                    City : obj['ResidentialAddressCity0' + i],
                    ZipCode : obj['ResidentialAddressPostalCode0' + i],
                    AddressType: obj['AddressType0' + i] ?? 'Main Address',
                }]
                helper.push(model);
            }
        })
        return helper;
    };

    AccountDoc = (obj, i) => {
        let helper = [];
        let num = i + 1;
        let aux1 =
        {
            index: i,
            TaskDescription: front1,
            DocumentType: documentName1,
            DocumentNumber: obj['numberIdentification0' + num],
            DocumentCountryID: obj['countryIDIdentification0' + num],
            DocumentName: obj['personalIdentification1FileName0' + num] !== '' ? obj['personalIdentification1FileName0' + num] : null,
            DocumentContent: obj['personalIdentification1File0' + num] !== '' ? obj['personalIdentification1File0' + num] : null,
            TaskDescriptionBack: back1,
            DocumentNameBack: obj['personalIdentification2FileName0' + num] !== '' ? obj['personalIdentification2FileName0' + num] : null,
            DocumentContentBack: obj['personalIdentification2File0' + num] !== '' ? obj['personalIdentification2File0' + num] : null,
        }
        let aux2 =
        {
            index: i,
            TaskDescription: front2,
            DocumentType: documentName2,
            DocumentNumber: obj['DriverLicenseNumber0' + num],
            DocumentCountryID: obj['countryIDIdentification0' + num],
            DocumentName: obj['drivingLicense1FileName0' + num] !== '' ? obj['drivingLicense1FileName0' + num] : null,
            DocumentContent: obj['drivingLicense1File0' + num] !== '' ? obj['drivingLicense1File0' + num] : null,
            TaskDescriptionBack: back2,
            DocumentNameBack: obj['drivingLicense2FileName0' + num] !== '' ? obj['drivingLicense2FileName0' + num] : null,
            DocumentContentBack: obj['drivingLicense2File0' + num] !== '' ? obj['drivingLicense2File0' + num] : null,
        }
        let aux3 =
        {
            index: i,
            TaskDescription: front3,
            DocumentType: documentName3,
            DocumentNumber: obj['PassportNumber0' + num],
            DocumentCountryID: obj['countryIDIdentification0' + num],
            DocumentName: obj['passportFileName0' + num] !== '' ? obj['passportFileName0' + num] : null,
            DocumentContent: obj['passportFile0' + num] !== '' ? obj['passportFile0' + num] : null,
            TaskDescriptionBack: '',
            DocumentNameBack: '',
            DocumentContentBack: '',
        }
        helper.push(aux1, aux2, aux3);
        if (getCurrentCompanyName() === 'Lighthouse') {
            helper = [...helper, ...[{
                index: 0,
                TaskDescription: personalIdentificationAdditional,
                DocumentType: documentName1,
                DocumentNumber: obj['numberIdentification0' + num],
                DocumentCountryID: obj['countryIDIdentification0' + num],
                AdditionalDocuments: obj.documents['identityAdditionalFiles0' + num]
            },
            {
                index: 0,
                TaskDescription: drivinLicenseAdditional,
                DocumentType: documentName2,
                DocumentNumber: obj['DriverLicenseNumber0' + num],
                DocumentCountryID: obj['countryIDIdentification0' + num],
                AdditionalDocuments: obj.documents['drivingLicenseAdditionalFiles0' + num]
            },
            {
                index: 0,
                TaskDescription: passportAdditional,
                DocumentType: documentName3,
                DocumentNumber: obj['PassportNumber0' + num],
                DocumentCountryID: obj['countryIDIdentification0' + num],
                AdditionalDocuments: obj.documents['passportAdditionalFiles0' + num]
            }]];
        }
        return helper;
    }

    getContactTaskDoc = (obj, i) => {
        let helper = this.state.helper_Step != null ? this.state.helper_Step : [];
        let aux1 =
        {
            index: i,
            TaskDescription: front1,
            DocumentType: documentName1,
            DocumentNumber: obj.numberIdentification,
            DocumentCountryID: obj.countryIDIdentification,
            DocumentName: obj.documents != null ? obj.documents.personalIdentification1FileName !== '' ? obj.documents.personalIdentification1FileName : null:null,
            DocumentContent: obj.documents != null ? obj.documents.personalIdentification1File !== '' ? obj.documents.personalIdentification1File : null: null,
            TaskDescriptionBack: back1,
            DocumentNameBack: obj.documents != null ? obj.documents.personalIdentification2FileName !== '' ? obj.documents.personalIdentification2FileName: null: null,
            DocumentContentBack: obj.documents != null ? obj.documents.personalIdentification2File !== '' ? obj.documents.personalIdentification2File : null:null,
        }
        let aux2 =
        {
            index: i,
            TaskDescription: front2,
            DocumentType: documentName2,
            DocumentNumber: obj.DriverLicenseNumber,
            DocumentCountryID: obj.countryIDIdentification,
            DocumentName: obj.documents != null ? obj.documents.drivingLicense1FileName !== '' ? obj.documents.drivingLicense1FileName : null:null,
            DocumentContent: obj.documents != null ? obj.documents.drivingLicense1File !== '' ? obj.documents.drivingLicense1File : null:null,
            TaskDescriptionBack: back2,
            DocumentNameBack: obj.documents != null ? obj.documents.drivingLicense2FileName !== '' ? obj.documents.drivingLicense2FileName : null:null,
            DocumentContentBack: obj.documents != null ? obj.documents.drivingLicense2File !== '' ? obj.documents.drivingLicense2File : null: null,
        }
        let aux3 =
        {
            index: i,
            TaskDescription: front3,
            DocumentType: documentName3,
            DocumentNumber: obj.PassportNumber,
            DocumentCountryID: obj.countryIDIdentification,
            DocumentName: obj.documents != null ? obj.documents.passportFileName !== '' ? obj.documents.passportFileName : null: null,
            DocumentContent: obj.documents != null ? obj.documents.passportFile !== '' ? obj.documents.passportFile : null : null,
            TaskDescriptionBack: '',
            DocumentNameBack: '',
            DocumentContentBack: '',
        }
        helper.push(aux1, aux2, aux3);
        this.setState({ helper_Step: helper }, () => { return helper; })
        return helper;
    }

    transformAddProperties = (type, obj, step) => {
        let table = [];
        switch (type) {
            default:
            case 'Corporate':
                table = this.state.valuesOfAdditionalPropertiesDefs1.concat(
                    this.state.valuesOfAdditionalPropertiesDefs2,
                    this.state.valuesOfAdditionalPropertiesDefs3,
                    this.state.valuesOfAdditionalPropertiesDefs4,
                    this.state.valuesOfAdditionalPropertiesDefs8);
                break;
            case 'Contact Principal':
                table = obj['valuesOfAdditionalPropertiesDefs' + step];
                break;
            case 'Contact01':
                table = this.state.valuesOfAdditionalPropertiesDefs6;
                break;
            case 'Contact02':
                table = this.state.valuesOfAdditionalPropertiesDefs7;
                break;
        }
        let helper = [];
        for (var j = 0; j < table.length; j++) {
            helper.push({
                Description: table[j].description,
                Value: table[j].value
            });
        }
        return helper;
    }
    customerDoc = (obj) => {
        const helper = [
            {
                index: 0,
                TaskDescription: obj.documentFiles.length > 0 ? obj.documentFiles[0].TaskName : '',
                DocumentType: 'Passport',
                DocumentNumber: 0,
                DocumentCountryID: '',
                DocumentName: obj.documentFiles.length > 0 ? obj.documentFiles[0].FileName : '',
                DocumentContent: obj.documentFiles.length > 0 ? obj.documentFiles[0].Content : '',
                TaskDescriptionBack: '',
                DocumentNameBack: '',
                DocumentContentBack: '',
            }
        ];
        return helper;
    }
    contactDoc = (obj, index) => {
        let helper = this.state.helper_Step != null ? this.state.helper_Step : [];
        let aux1 =
        {
            index: 0,
            TaskDescription: '',
            DocumentType: 'Passport',
            DocumentNumber: 0,
            DocumentCountryID: '',
            DocumentName: '',
            DocumentContent: '',
            TaskDescriptionBack: '',
            DocumentNameBack: '',
            DocumentContentBack: '',
        };
        let aux3 = null;
        if (helper.length > 0) { helper.splice(index); }
        aux1.index = index;
        aux1.TaskDescription = obj.documentFiles.length > 0 ? obj.documentFiles[0].TaskName : '';
        aux1.DocumentName = obj.documentFiles.length > 0 ? obj.documentFiles[0].FileName : '';
        aux1.DocumentContent = obj.documentFiles.length > 0 ? obj.documentFiles[0].Content : '';
        aux3 = aux1;
        helper.push(aux3);
        this.setState({ helper_Step: helper }, () => { return helper; })
        return helper;
    }
    handleActiveTab = (tab) => {
        return this.state.activeTab === tab ? 'accordion-header uk-accordion-title clearfix active' : 'accordion-header uk-accordion-title clearfix';
    }
    //----------OBJ --------------
    Generalbusinessinformation = (obj) => {
        this.setState({ generalbusinessinformation: obj });
    }
    Principalplaceofbusiness = (obj) => {
        this.setState({ principalplaceofbusiness: obj });
    }
    Productsandservices = (obj) => {
        this.setState({ productsandservices: obj });
    }
    Foreignexchangeforwards = (obj) => {
        this.setState({ foreignexchangeforwards: obj });
    }
    Accountprimarycontact = (obj) => {
        this.setState({ accountprimarycontact: obj });
    }
    Directorandappointedofficer = (obj) => {
        this.setState({ directorandappointedofficer: obj });
    }
    Ultimatebeneficialowner = (obj) => {
        this.setState({ ultimatebeneficialowner: obj });
    }
    Acceptanceoftermsandconditions = (obj) => {
        this.setState({ acceptanceoftermsandconditions: obj });
    }

    Owners = (obj) => {
        this.setState({ owners: obj });
    };

    OnClickNext = () => {
        this.setState({ clickNext: true })
    }
    NoClickNext = () => {
        this.setState({ clickNext: false })
    }
    transformToIndex = (tabID) => {
        switch (tabID) {
            default:
            case 'general-business-information-li':
                return 1;
            case 'principal-place-of-business-li':
                return 2;
            // case 'products-and-services-li':
            //     return 3;
            // case 'foreign-exchange-forwards-li':
            //     return this.compareSteps(4);
            case 'account-primary-contact-li':
                return this.compareSteps(3);
            case 'directors-li':
                return this.compareSteps(4);
            case 'owners-li':
                return this.compareSteps(5);
            // case 'directorand-appointed-officer-li':
            //     return this.compareSteps(6);
            // case 'ultimate-beneficial-owner-li':
            //     if (this.state.yesDirector) {
            //         return this.compareSteps(7);
            //     } else {
            //         return this.compareSteps(6);
            //     }
            case 'acceptance-of-terms-and-conditions-li':
                if (!this.state.yesDirector && !this.state.yesUltimate) {
                    return this.compareSteps(5);
                } else {
                    return this.compareSteps(6);
                }
        }
    };
    compareSteps = (number) => {
        // if (this.state.allAdditionalPropertiesDefs3.length === 0 && this.state.allAdditionalPropertiesDefs4.length === 0) {
        //     number -= 2;
        // } else if (this.state.allAdditionalPropertiesDefs3.length > 0 && this.state.allAdditionalPropertiesDefs4.length === 0) {
        //     number -= 1;
        // } else if (this.state.allAdditionalPropertiesDefs3.length === 0 && this.state.allAdditionalPropertiesDefs4.length > 0) {
        //     number -= 1;
        // }
        return number
    }

    PreviousButton = (stepWantToGo) => {
        switch (stepWantToGo) {
            default:
                break;
        }
        this.OnClickNext();
        this.setState({ nextTabIndex: stepWantToGo, tabIndex: stepWantToGo + 1, buttonPressed: true }, () =>
            UIkit.accordion('#accordion-contact').toggle(stepWantToGo, true)
        );
    }
    NextButton = (stepWantToGo) => {
        switch (stepWantToGo) {
            case 4:
                stepWantToGo = this.compareSteps(stepWantToGo);
                break;
            case 5:
                stepWantToGo = this.compareSteps(stepWantToGo);
                break;
            case 6:
                stepWantToGo = this.compareSteps(stepWantToGo);
                break;
            case 7:
                stepWantToGo = this.compareSteps(stepWantToGo);
                break;
            default:
                break;
        }
        this.OnClickNext();
        this.setState({ nextTabIndex: stepWantToGo, tabIndex: stepWantToGo - 1, buttonPressed: true }, () =>
            UIkit.accordion('#accordion-contact').toggle(stepWantToGo, true)
        );
    }

    closeInvalidReferralAlert = async () => {
        this.setState({ showInvalidReferralAlert: false });
        await this.selectSaveMethod(this.state.saveType);
    };

    CustomerAgreement = (obj) => {
        this.setState({ CustomerAgreement: obj });
    }

    closeSuccessAlert = async () => {
        const status = sessionStorage.getItem('CustomerStatus');
        this.setState({ showSuccessAlert: false });
        if (this.state.originOnline !== '') {
            window.open(onlineLoginUrl, "_self")
        } else {
            // const parameterResponse = await getParameterByName('DisableSendEmailRegform');
            // const disableSendEmailRegform = parameterResponse != null && parameterResponse.ParameterValue != null && parameterResponse.ParameterValue.toLowerCase() === 'true';
            // if (disableSendEmailRegform) {
            //     this.props.navigate('/success-account');
            // } else {
            // }
            switch (status) {
                case 'Incomplete':
                    this.props.navigate('/');
                    break;
                case 'Complete':
                    this.props.navigate('/success-account');
                    break;
                case 'Partial':
                    this.props.navigate('/');
                    break;
                default:
                    break;
            }
        }
    }

    closeSentEmailAlert = () => {
        this.setState({
            showSentEmailAlert: false,
            alertTitleSentEmail: '',
            alertMessageSentEmail: ''
        }, () => {
            // this.submitAfterEmailVerification('Partial');
        });
    }

    closeEmailVerifiedAlert = () => {
        this.setState({ emailVerifiedSubmit: false }, () => {
            // this.submitAfterEmailVerification('Partial');
        });
    }

    yesEmailVerifiedAlert = async () => {
        sendValidateEmail(this.state.customer.CustomerId, this.state.accountprimarycontact.FirstName01, this.state.accountprimarycontact.EmailAddress01).then(
            (jsonResponse) => {
                let title = '';
                let message = '';

                if (jsonResponse != null) {
                    switch (jsonResponse.httpStatusCode) {
                        case 200:
                            title = getResource('VerifyEmailTitle');
                            message = getResource('VerifyEmailText');
                            break;
                        default:
                            title = 'Error';
                            message = 'Something went wrong. Please try again later.';
                            break;
                    }
                } else {
                    title = 'Error';
                    message = 'Something went wrong. Please try again later.';
                }

                this.setState({
                    emailVerifiedSubmit: false,
                    showSentEmailAlert: true,
                    alertTitleSentEmail: title,
                    alertMessageSentEmail: message
                });
            }
        );
    }

    adaptList = (customer, originOnline) => {
        if (customer != null) {
            let contactAdditionalProperties = [],
                contact01AdditionalProperties = [],
                contact02AdditionalProperties = [];
            let objTaskAttachments = customer.CustomerTask != null ? customer.CustomerTask : []
            let objGeneralbusinessinformation = {
                companyName: customer.name,
                companytypeID: '',
                countryID: '',
                countryName: '',
                companytypeName: '',
                naicsID: '',
                naicsName: '',
                FederalTaxID: '',
                FederalTaxName: '',
                registrationNumber: '',
                DateOfBirth: '',
                website: '',
                ResidentialPhoneNumber: customer.phonenumber != null ? customer.phonenumber : '',
                doingBusinessAs: '',
                stateID:'',
                stateName:'',
                AffiliateReferredBy: customer.AffiliateReferredBy != null ? customer.AffiliateReferredBy : '',
                IndustrySector: customer.industrycode != null ? customer.industrycode : ''
            };
            let objGrincipalplaceofbusiness = {
                AddressId: customer.CustomerAddresses != null ? customer.CustomerAddresses[0].AddressId : '',
                AddressUpdateToken: customer.CustomerAddresses != null ? customer.CustomerAddresses[0].UpdateToken : '',

                BusinessAddressStreet: customer.CustomerAddresses != null ? customer.CustomerAddresses[0].address1 : '',
                BusinessAddressLine2: customer.CustomerAddresses != null ? customer.CustomerAddresses[0].address2 : '',
                BusinessAddressCityID: customer.CustomerAddresses != null ? customer.CustomerAddresses[0].city : '',
                BusinessAddressCityName: customer.CustomerAddresses != null ? customer.CustomerAddresses[0].city : '',
                BusinessAddressStateId: customer.CustomerAddresses != null ? customer.CustomerAddresses[0].stateid : '',
                OtherState: customer.CustomerAddresses != null ? customer.CustomerAddresses[0].statename : '',
                BusinessAddressPostalCode: customer?.CustomerAddresses[0]?.postalcode ?? '',
                //ddwns values
                countryID: customer.CustomerAddresses != null ? customer.CustomerAddresses[0].countryid : '',
                countryName: '',
            };
            let objGroductsandservices = {
                DescriptionOfBusiness: '',
                ExpectedMonthlyPayments: '',
                ExpectedMonthlyVolume: '',
                TradingCountries: '',
            };
            let objGoreignexchangeforwards = {
                LEINumber: '',
                LEIExpirationDate: ''
            };
            let objGccountprimarycontact = {};
            let objGirectorandappointedofficer = {
                FirstName: '',
                ShortName: '',
                LastName: '',
                DateOfBirth: '',
                ResidentialAddressStreet: '',
                ResidentialAddressLine2: '',
                ResidentialAddressCity: '',
                ResidentialAddressStateId: '',
                ResidentialAddressStateName: '',
                OtherState: '',

                countryIDIdentification: '',
                StateIdIdentification: '',
                StateNameIdentification: '',
                OtherStateIdentification: '',

                ResidentialCityId: '',
                ResidentialCityName: '',
                ResidentialAddressPostalCode: '',
                ResidentialPhoneNumber: '',
                EmailAddress: '',
                Fax: '',
                MobileNumber: '',
                Citizenship: '',
                SSN: '',
                IDNo: '',
                IDType: '',
                IDTypeName: '',
                IDExpirationDate: '',
                saludationID: '',
                saludationName: '',
                individualRolesID: '',
                individualRolesName: '',
                jobTitlesID: '',
                jobTitlesName: '',
                countryID: '',
                countryName: '',
                CitizenshipID: '',
                CitizenshipName: '',
                PercentOwnership: '',
                IssueJurisdiction: '',
            };
            let objGltimatebeneficialowner = {
                FirstName: '',
                ShortName: '',
                LastName: '',
                DateOfBirth: '',
                ResidentialAddressStreet: '',
                ResidentialAddressLine2: '',
                ResidentialAddressCity: '',
                ResidentialAddressStateId: '',
                ResidentialAddressStateName: '',
                OtherState: '',

                countryIDIdentification: '',
                StateIdIdentification: '',
                StateNameIdentification: '',
                OtherStateIdentification: '',

                ResidentialCityId: '',
                ResidentialCityName: '',
                ResidentialAddressPostalCode: '',
                ResidentialPhoneNumber: '',
                EmailAddress: '',
                Fax: '',
                MobileNumber: '',
                Citizenship: '',
                SSN: '',
                IDNo: '',
                IDType: '',
                IDTypeName: '',
                IDExpirationDate: '',
                saludationID: '',
                saludationName: '',
                individualRolesID: '',
                individualRolesName: '',
                jobTitlesID: '',
                jobTitlesName: '',
                countryID: '',
                countryName: '',
                CitizenshipID: '',
                CitizenshipName: '',
                PercentOwnership: '',
                IssueJurisdiction: '',
            };
            /*let objGcceptanceoftermsandconditions = {
                customerAgreement: false,
            };*/
            let objGOwners = {};
            if (customer.CustomerOwners != null && customer.CustomerOwners.length > 0) {
                customer.CustomerOwners.forEach((value, index) => {
                    let numContact = index + 1;
                    objGOwners['OwnerId0' + numContact] = value.OwnerId ?? 0;
                    objGOwners['ContactId0' + numContact] = value.ContactId != null ? value.ContactId : '';
                    objGOwners["SalutationID0" + numContact] = value.Salutation != null ? value.Salutation : '';
                    objGOwners["FirstName0" + numContact] = value.FirstName ?? '';
                    objGOwners["ShortName0" + numContact] = value.middlename != null ? value.middlename : '';
                    objGOwners["LastName0" + numContact] = value.LastName ?? '';
                    objGOwners["DateOfBirth0" + numContact] = value.date_Of_birth ?? '';
                    objGOwners["PercentageOwnership0" + numContact] = value.PercentageOwnership ?? '';
                    objGOwners["EmailAddress0" + numContact] = value.Email ?? '';
                    objGOwners["Status0" + numContact] = value.Status ?? '';
                    objGOwners["OwnerType0" + numContact] = value.OwnerType ?? 'Individual';
                    let _address = value.CustomerAddresses?.[0] ?? null;
                    objGOwners["ResidentialAddressPostalCode0" + numContact] = _address?.postalcode ?? '';
                    objGOwners["ResidentialAddressStreet0" + numContact] = _address?.address1 ?? '';
                    objGOwners["ResidentialAddressLine20" + numContact] = _address?.address2 ?? '';
                    objGOwners["ResidentialAddressCity0" + numContact] = _address?.city ?? '';
                    objGOwners["countryID0" + numContact] = _address?.countryid ?? '';
                    objGOwners["ResidentialAddressStateId0" + numContact] = _address?.stateid ?? '';
                    objGOwners["ResidentialPhoneNumber0" + numContact] = (customer.mobilenumber != null && customer.mobilenumber !== '') ? customer.mobilenumber : value.Phone != null ? value.Phone : '';
                    objGOwners["OtherState0" + numContact] = _address?.statename ?? '';
                    objGOwners["AddressType0" + numContact] = _address?.type ?? '';
                    // objGOwners["PEP0" + numContact] = value.politicallyexposedperson != null && value.politicallyexposedperson === 'Yes';
                    objGOwners['trader0' + numContact] = true;
                    objGOwners['TaskList0' + numContact] = value.OwnerTask != null ? value.OwnerTask : [];
                    /*
                    var countryIDIdentification = this.contactProperty(value.OwnerProperties, 'Country ID Identification');
                    objGOwners["countryIDIdentification0" + numContact] = (countryIDIdentification == '') ? (value.countryid != null ? value.countryid : '') : countryIDIdentification;// this.contactProperty(value.ContactProperties, 'Country ID Identification');
              */

                    if (value.OwnerTask != null) {
                        value.OwnerTask.forEach((document) => {
                            switch (document.Title) {
                                case 'Driving License Document - 1': {
                                    if(document)
                                    {
                                        objGOwners['DriverLicenseNumber0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                        objGOwners["countryIDIdentification0" + numContact] = document.CountryID != null ? document.CountryID : '';
              
                                    if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                        objGOwners['drivingLicense1File0' + numContact] = document.Files[0]['$content'];
                                        objGOwners['drivingLicense1FileName0' + numContact] = document.Files[0]['filename'];
                                        
                                    }
                                }
                                    break;
                                }
                                case 'Driving License Document - 2': {
                                    if(document)
                                    {
                                        objGOwners['DriverLicenseNumber0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                    objGOwners["countryIDIdentification0" + numContact] = document.CountryID != null ? document.CountryID : '';
                                    if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                        objGOwners['drivingLicense2File0' + numContact] = document.Files[0]['$content'];
                                        objGOwners['drivingLicense2FileName0' + numContact] = document.Files[0]['filename'];
                                        
                                    }
                                }
                                    break;
                                }
                                case 'Personal Identification document - 1': {
                                    if(document)
                                    {
                                        objGOwners['numberIdentification0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                        objGOwners["countryIDIdentification0" + numContact] = document.CountryID != null ? document.CountryID : '';
                                    if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                        objGOwners['personalIdentification1File0' + numContact] = document.Files[0]['$content'];
                                        objGOwners['personalIdentification1FileName0' + numContact] = document.Files[0]['filename'];
                                        
                                    }
                                }
                                    break;
                                }
                                case 'Personal Identification document - 2': {
                                    if(document)
                                    {
                                        objGOwners['numberIdentification0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                        objGOwners["countryIDIdentification0" + numContact] = document.CountryID != null ? document.CountryID : '';
                                    if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                        objGOwners['personalIdentification2File0' + numContact] = document.Files[0]['$content'];
                                        objGOwners['personalIdentification2FileName0' + numContact] = document.Files[0]['filename'];
                                        
                                    }
                                }
                                    break;
                                }
                                case 'Personal Passport Document - 1': {
                                    if(document)
                                    {objGOwners['PassportNumber0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                    objGOwners["countryIDIdentification0" + numContact] = document.CountryID != null ? document.CountryID : '';
                                    if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                        objGOwners['passportFile0' + numContact] = document.Files[0]['$content'];
                                        objGOwners['passportFileName0' + numContact] = document.Files[0]['filename'];
                                        
                                    }
                                }
                                    break;
                                }
                                case 'Personal Identification document - Add. Pages': {
                                    if (document){//.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                        objGOwners['numberIdentification0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                    }
                                    break;
                                }
                                case 'Driving License Document - Add. Pages': {
                                    if (document){//.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                        objGOwners['DriverLicenseNumber0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                    }
                                    break;
                                }
                                case 'Personal Passport Document - Add. Pages': {
                                    if (document){ //.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                        objGOwners['PassportNumber0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                    }
                                    break;
                                }
                                default: break;
                            }
                        });
                    }
                });
            } else {
                objGOwners = null;
            }

            let objCustomerAgreement = {
                customerAgreement: '',
                TaskList: customer.CustomerTask != null ? customer.CustomerTask : []
            }
            let objTaskAttachments_Step5 = [], objTaskAttachments_Step6 = [], objTaskAttachments_Step7 = [], yesDirector = false, yesUltimate = false;
            if (customer.Contacts != null) {
                const mainContact = customer.Contacts.filter(c => c.mainContact);
                const otherContacts = customer.Contacts.filter(c => !c.mainContact);
                const accountUsers = otherContacts.filter(oc => this.contactProperty(oc.ContactProperties, 'Is Contact').toLowerCase() === 'yes');
                const directors = otherContacts.filter(oc => this.contactProperty(oc.ContactProperties, 'Is Contact').toLowerCase() !== 'yes');
                [...mainContact, ...accountUsers].forEach((value, index) => {
                    const numContact = index + 1;
                    objGccountprimarycontact['ContactAddressId0' + numContact] = value.AddressId != null ? value.AddressId : '';
                    objGccountprimarycontact['ContactAddressUpdateToken0' + numContact] = value.AddressUpdateToken != null ? value.AddressUpdateToken : '';
                    objGccountprimarycontact['ContactId0' + numContact] = value.ContactId != null ? value.ContactId : '';
                    // objGccountprimarycontact["saludationID0" + numContact] = value.Salutation != null ? value.Salutation : '';
                    objGccountprimarycontact["SalutationID0" + numContact] = value.Salutation != null ? value.Salutation : '';
                    objGccountprimarycontact["FirstName0" + numContact] = value.firstname != null ? value.firstname : '';
                    objGccountprimarycontact["ShortName0" + numContact] = value.middlename != null ? value.middlename : '';
                    objGccountprimarycontact["LastName0" + numContact] = value.lastname != null ? value.lastname : '';
                    objGccountprimarycontact["ResidentialAddressPostalCode0" + numContact] = value.PostalCode != null ? value.PostalCode : '';
                    objGccountprimarycontact["ResidentialAddressStreet0" + numContact] = value.address1 != null ? value.address1 : '';
                    objGccountprimarycontact["ResidentialAddressLine20" + numContact] = value.address2 != null ? value.address2 : '';
                    objGccountprimarycontact["ResidentialAddressCity0" + numContact] = value.city != null ? value.city : '';
                    objGccountprimarycontact["countryID0" + numContact] = value.countryid != null ? value.countryid : '';
                    objGccountprimarycontact["DateOfBirth0" + numContact] = value.dateofbirth != null ? value.dateofbirth : '';
                    objGccountprimarycontact["EmailAddress0" + numContact] = value.email != null ? value.email : '';
                    objGccountprimarycontact["ResidentialPhoneNumber0" + numContact] = (customer.mobilenumber != null && customer.mobilenumber !== '') ? customer.mobilenumber : value.phonenumber != null ? value.phonenumber : '';
                    objGccountprimarycontact["ResidentialAddressStateId0" + numContact] = value.stateid != null ? value.stateid : '';
                    objGccountprimarycontact["OtherState0" + numContact] = value.statename != null ? value.statename : '';
                    objGccountprimarycontact["PEP0" + numContact] = value.politicallyexposedperson != null && value.politicallyexposedperson === 'Yes';

                    //----Properties
                    objGccountprimarycontact["individualRolesID0" + numContact] = this.contactProperty(value.ContactProperties, 'Individual Roles');
                    objGccountprimarycontact["IssueJurisdiction0" + numContact] = this.contactProperty(value.ContactProperties, 'Issue Jurisdiction');
                    objGccountprimarycontact["jobTitlesID0" + numContact] = this.contactProperty(value.ContactProperties, 'Job Title Other');
                    objGccountprimarycontact["IDNo0" + numContact] = this.contactProperty(value.ContactProperties, 'Id No');
                    objGccountprimarycontact["IDType0" + numContact] = this.contactProperty(value.ContactProperties, 'Id Type');
                    objGccountprimarycontact["IDExpirationDate0" + numContact] = this.contactProperty(value.ContactProperties, 'Id Expiration Date');
                    objGccountprimarycontact["PercentOwnership0" + numContact] = this.contactProperty(value.ContactProperties, 'Percent Ownership');
                    objGccountprimarycontact["CitizenshipID0" + numContact] = this.contactProperty(value.ContactProperties, 'Citizenship');
                    objGccountprimarycontact["SSN0" + numContact] = this.contactProperty(value.ContactProperties, 'SSN');
                    //Xushi
                    objGccountprimarycontact['Roles0' + numContact] = this.roleList(value.ContactProperties);
                    objGccountprimarycontact["EasyPayAuthorised0" + numContact] = this.contactProperty(value.ContactProperties, 'EasyPay Authorised') === 'Yes';

                    var countryIDIdentification = this.contactProperty(value.ContactProperties, 'Country ID Identification');
                    objGccountprimarycontact["countryIDIdentification0" + numContact] = (countryIDIdentification == '') ? (value.countryid != null ? value.countryid : '') : countryIDIdentification;//this.contactProperty(value.ContactProperties, 'Country ID Identification');
                    objGccountprimarycontact["StateIdIdentification0" + numContact] = this.contactProperty(value.ContactProperties, 'State ID Identification');
                    objGccountprimarycontact["OtherStateIdentification0" + numContact] = this.contactProperty(value.ContactProperties, 'State Name Identification');
                    //----Tasks
                    //objTaskAttachments_Step5 = value.ContactTask != null ? value.ContactTask : [];
                    objGccountprimarycontact['TaskList0' + numContact] = value.ContactTask != null ? value.ContactTask : [];
                    objGccountprimarycontact['contactAdditionalProperties0' + numContact] = value.AdditionalProperties != null ? value.AdditionalProperties : [];
                    objGccountprimarycontact['trader0' + numContact] = true;
                    
                    // objGccountprimarycontact['numberIdentification0' + numContact] = this.contactProperty(value.ContactProperties, 'Identification Number');
                    // objGccountprimarycontact['DriverLicenseNumber0' + numContact] = this.contactProperty(value.ContactProperties, "Driver's License Number");
                    // objGccountprimarycontact['PassportNumber0' + numContact] = this.contactProperty(value.ContactProperties, 'Passport Number');
                    if (value.ContactTask != null) {
                        value.ContactTask.forEach((document) => {
                            switch (document.Title) {
                                case 'Driving License Document - 1': {
                                    if(document) {
                                        objGccountprimarycontact['DriverLicenseNumber0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                        if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                            objGccountprimarycontact['drivingLicense1File0' + numContact] = document.Files[0]['$content'];
                                            objGccountprimarycontact['drivingLicense1FileName0' + numContact] = document.Files[0]['filename'];
                                        }
                                    }
                                    break;
                                }
                                case 'Driving License Document - 2': {
                                    if(document) {
                                        objGccountprimarycontact['DriverLicenseNumber0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                        if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                            objGccountprimarycontact['drivingLicense2File0' + numContact] = document.Files[0]['$content'];
                                            objGccountprimarycontact['drivingLicense2FileName0' + numContact] = document.Files[0]['filename'];
                                        }
                                    }
                                    break;
                                }
                                case 'Personal Identification document - 1': {
                                    if(document) {
                                        objGccountprimarycontact['numberIdentification0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                        if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                            objGccountprimarycontact['personalIdentification1File0' + numContact] = document.Files[0]['$content'];
                                            objGccountprimarycontact['personalIdentification1FileName0' + numContact] = document.Files[0]['filename'];
                                        }
                                    }
                                    break;
                                }
                                case 'Personal Identification document - 2': {
                                    if(document) {
                                        objGccountprimarycontact['numberIdentification0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                        if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                            objGccountprimarycontact['personalIdentification2File0' + numContact] = document.Files[0]['$content'];
                                            objGccountprimarycontact['personalIdentification2FileName0' + numContact] = document.Files[0]['filename'];
                                        }
                                    }
                                    break;
                                }
                                case 'Personal Passport Document - 1': {
                                    if(document) {
                                        objGccountprimarycontact['PassportNumber0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                        if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                            objGccountprimarycontact['passportFile0' + numContact] = document.Files[0]['$content'];
                                            objGccountprimarycontact['passportFileName0' + numContact] = document.Files[0]['filename'];
                                        }
                                    }
                                    break;
                                }
                                case 'Personal Identification document - Add. Pages': {
                                    if (document) {//.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                        objGccountprimarycontact['numberIdentification0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                    }
                                    break;
                                }
                                case 'Driving License Document - Add. Pages': {
                                    if (document) {//.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                        objGccountprimarycontact['DriverLicenseNumber0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                    }
                                    break;
                                }
                                case 'Personal Passport Document - Add. Pages': {
                                    if (document) { //.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                        objGccountprimarycontact['PassportNumber0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                    }
                                    break;
                                }
                                default: break;
                            }
                        });
                    }
                })
                directors.forEach((value, index) => {
                    const numContact = index + 1;
                    objGirectorandappointedofficer['ContactAddressId0' + numContact] = value.AddressId != null ? value.AddressId : '';
                    objGirectorandappointedofficer['ContactAddressUpdateToken0' + numContact] = value.AddressUpdateToken != null ? value.AddressUpdateToken : '';
                    objGirectorandappointedofficer['ContactId0' + numContact] = value.ContactId != null ? value.ContactId : '';
                    // objGirectorandappointedofficer["saludationID0" + numContact] = value.Salutation != null ? value.Salutation : '';
                    objGirectorandappointedofficer["SalutationID0" + numContact] = value.Salutation != null ? value.Salutation : '';
                    objGirectorandappointedofficer["FirstName0" + numContact] = value.firstname != null ? value.firstname : '';
                    objGirectorandappointedofficer["ShortName0" + numContact] = value.middlename != null ? value.middlename : '';
                    objGirectorandappointedofficer["LastName0" + numContact] = value.lastname != null ? value.lastname : '';
                    objGirectorandappointedofficer["ResidentialAddressPostalCode0" + numContact] = value.PostalCode != null ? value.PostalCode : '';
                    objGirectorandappointedofficer["ResidentialAddressStreet0" + numContact] = value.address1 != null ? value.address1 : '';
                    objGirectorandappointedofficer["ResidentialAddressLine20" + numContact] = value.address2 != null ? value.address2 : '';
                    objGirectorandappointedofficer["ResidentialAddressCity0" + numContact] = value.city != null ? value.city : '';
                    objGirectorandappointedofficer["countryID0" + numContact] = value.countryid != null ? value.countryid : '';
                    objGirectorandappointedofficer["DateOfBirth0" + numContact] = value.dateofbirth != null ? value.dateofbirth : '';
                    objGirectorandappointedofficer["EmailAddress0" + numContact] = value.email != null ? value.email : '';
                    objGirectorandappointedofficer["ResidentialPhoneNumber0" + numContact] = (customer.mobilenumber != null && customer.mobilenumber !== '') ? customer.mobilenumber : value.phonenumber != null ? value.phonenumber : '';
                    objGirectorandappointedofficer["ResidentialAddressStateId0" + numContact] = value.stateid != null ? value.stateid : '';
                    objGirectorandappointedofficer["OtherState0" + numContact] = value.statename != null ? value.statename : '';
                    objGirectorandappointedofficer["PEP0" + numContact] = value.politicallyexposedperson != null && value.politicallyexposedperson === 'Yes';

                    //----Properties
                    objGirectorandappointedofficer["individualRolesID0" + numContact] = this.contactProperty(value.ContactProperties, 'Individual Roles');
                    objGirectorandappointedofficer["IssueJurisdiction0" + numContact] = this.contactProperty(value.ContactProperties, 'Issue Jurisdiction');
                    objGirectorandappointedofficer["jobTitlesID0" + numContact] = this.contactProperty(value.ContactProperties, 'Job Title Other');
                    objGirectorandappointedofficer["IDNo0" + numContact] = this.contactProperty(value.ContactProperties, 'Id No');
                    objGirectorandappointedofficer["IDType0" + numContact] = this.contactProperty(value.ContactProperties, 'Id Type');
                    objGirectorandappointedofficer["IDExpirationDate0" + numContact] = this.contactProperty(value.ContactProperties, 'Id Expiration Date');
                    objGirectorandappointedofficer["PercentOwnership0" + numContact] = this.contactProperty(value.ContactProperties, 'Percent Ownership');
                    objGirectorandappointedofficer["CitizenshipID0" + numContact] = this.contactProperty(value.ContactProperties, 'Citizenship');
                    objGirectorandappointedofficer["SSN0" + numContact] = this.contactProperty(value.ContactProperties, 'SSN');
                    //Xushi
                    objGirectorandappointedofficer['Roles0' + numContact] = this.roleList(value.ContactProperties);
                    objGirectorandappointedofficer["EasyPayAuthorised0" + numContact] = this.contactProperty(value.ContactProperties, 'EasyPay Authorised') === 'Yes';

                    var countryIDIdentification = this.contactProperty(value.ContactProperties, 'Country ID Identification');
                    objGirectorandappointedofficer["countryIDIdentification0" + numContact] = (countryIDIdentification == '') ? (value.countryid != null ? value.countryid : '') : countryIDIdentification;//this.contactProperty(value.ContactProperties, 'Country ID Identification');
                    objGirectorandappointedofficer["StateIdIdentification0" + numContact] = this.contactProperty(value.ContactProperties, 'State ID Identification');
                    objGirectorandappointedofficer["OtherStateIdentification0" + numContact] = this.contactProperty(value.ContactProperties, 'State Name Identification');
                    //----Tasks
                    //objTaskAttachments_Step5 = value.ContactTask != null ? value.ContactTask : [];
                    objGirectorandappointedofficer['TaskList0' + numContact] = value.ContactTask != null ? value.ContactTask : [];
                    objGirectorandappointedofficer['contactAdditionalProperties0' + numContact] = value.AdditionalProperties != null ? value.AdditionalProperties : [];
                    objGirectorandappointedofficer['trader0' + numContact] = true;
                    
                    // objGirectorandappointedofficer['numberIdentification0' + numContact] = this.contactProperty(value.ContactProperties, 'Identification Number');
                    // objGirectorandappointedofficer['DriverLicenseNumber0' + numContact] = this.contactProperty(value.ContactProperties, "Driver's License Number");
                    // objGirectorandappointedofficer['PassportNumber0' + numContact] = this.contactProperty(value.ContactProperties, 'Passport Number');
                    if (value.ContactTask != null) {
                        value.ContactTask.forEach((document) => {
                            switch (document.Title) {
                                case 'Driving License Document - 1': {
                                    if(document) {
                                        objGirectorandappointedofficer['DriverLicenseNumber0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                        if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                            objGirectorandappointedofficer['drivingLicense1File0' + numContact] = document.Files[0]['$content'];
                                            objGirectorandappointedofficer['drivingLicense1FileName0' + numContact] = document.Files[0]['filename'];
                                        }
                                    }
                                    break;
                                }
                                case 'Driving License Document - 2': {
                                    if(document) {
                                        objGirectorandappointedofficer['DriverLicenseNumber0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                        if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                            objGirectorandappointedofficer['drivingLicense2File0' + numContact] = document.Files[0]['$content'];
                                            objGirectorandappointedofficer['drivingLicense2FileName0' + numContact] = document.Files[0]['filename'];
                                        }
                                    }
                                    break;
                                }
                                case 'Personal Identification document - 1': {
                                    if(document) {
                                        objGirectorandappointedofficer['numberIdentification0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                        if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                            objGirectorandappointedofficer['personalIdentification1File0' + numContact] = document.Files[0]['$content'];
                                            objGirectorandappointedofficer['personalIdentification1FileName0' + numContact] = document.Files[0]['filename'];
                                        }
                                    }
                                    break;
                                }
                                case 'Personal Identification document - 2': {
                                    if(document) {
                                        objGirectorandappointedofficer['numberIdentification0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                        if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                            objGirectorandappointedofficer['personalIdentification2File0' + numContact] = document.Files[0]['$content'];
                                            objGirectorandappointedofficer['personalIdentification2FileName0' + numContact] = document.Files[0]['filename'];
                                        }
                                    }
                                    break;
                                }
                                case 'Personal Passport Document - 1': {
                                    if(document) {
                                        objGirectorandappointedofficer['PassportNumber0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                        if (document.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                            objGirectorandappointedofficer['passportFile0' + numContact] = document.Files[0]['$content'];
                                            objGirectorandappointedofficer['passportFileName0' + numContact] = document.Files[0]['filename'];
                                        }
                                    }
                                    break;
                                }
                                case 'Personal Identification document - Add. Pages': {
                                    if (document) {//.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                        objGirectorandappointedofficer['numberIdentification0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                    }
                                    break;
                                }
                                case 'Driving License Document - Add. Pages': {
                                    if (document) {//.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                        objGirectorandappointedofficer['DriverLicenseNumber0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                    }
                                    break;
                                }
                                case 'Personal Passport Document - Add. Pages': {
                                    if (document) { //.Files != null && document.Files.length > 0 && document.Files[0]['$content'] != null) {
                                        objGirectorandappointedofficer['PassportNumber0' + numContact] = document.DocumentNumber != null ? document.DocumentNumber : '';
                                    }
                                    break;
                                }
                                default: break;
                            }
                        });
                    }
                })
            }

            let emailVerified = 'NO';
            let showEmailVerifiedAlert = false;

            if (customer.CustomerProperties != null) {
                customer.CustomerProperties.forEach(value => {
                    switch (value.name) {
                        case 'EmailVerified':
                            emailVerified = value.value;

                            switch (value.value) {
                                case 'NO':
                                    showEmailVerifiedAlert = true;
                                    break;
                                case 'YES':
                                    showEmailVerifiedAlert = false;
                                    break;
                                default:
                                    break;
                            }
                            break;
                        // case 'Business Telephone Number':
                        //     objGeneralbusinessinformation.ResidentialPhoneNumber = value.value;
                        //     break;
                        case 'CountryOfIncorporation':
                            objGeneralbusinessinformation.countryID = value.value;
                            break;
                        case 'Website':
                            objGeneralbusinessinformation.website = value.value;
                            break;
                        case 'Customer Agreement':
                            //objGcceptanceoftermsandconditions.customerAgreement = value.value !== '' ? (value.value === 'Yes' ? true : false) : false;
                            objCustomerAgreement.customerAgreement = value?.value === 'Yes' ?? false;
                            break;
                        case 'Include Signature':
                            objCustomerAgreement.signatureResponse = value?.value === 'Yes' ?? false;
                            break;
                        case 'State Region of Incorporation':
                            objGeneralbusinessinformation.stateID = value.value;
                            //objGeneralbusinessinformation.stateName = value.value;
                            break;
                        /*         case 'Doing Business As':
                                    objGeneralbusinessinformation.doingBusinessAs = value.value;
                                    break;
                                case 'Date of Formation':
                                    objGeneralbusinessinformation.DateOfBirth = value.value != null ? value.value : "";
                                    break;
                                case 'Federal Tax Id':
                                    objGeneralbusinessinformation.FederalTaxID = value.value;
                                    break;
                                case 'Company Registration No':
                                    objGeneralbusinessinformation.registrationNumber = value.value;
                                    break;
                                case 'NAICS':
                                    objGeneralbusinessinformation.naicsID = value.value;
                                    objGeneralbusinessinformation.naicsName = value.value;
                                    break;
                                case 'Company Structure':
                                    objGeneralbusinessinformation.companytypeID = value.value;
                                    objGeneralbusinessinformation.companytypeName = value.value;
                                    break;
                                case 'Description of Business':
                                    objGroductsandservices.DescriptionOfBusiness = value.value;
                                    break;
                                case 'Expected Monthly Payments':
                                    objGroductsandservices.ExpectedMonthlyPayments = value.value;
                                    break;
                                case 'Expected Monthly Volume':
                                    objGroductsandservices.ExpectedMonthlyVolume = value.value;
                                    break;
                                case 'Trading Countries':
                                    objGroductsandservices.TradingCountries = value.value;
                                    break;
                                case 'LEI Number':
                                    objGoreignexchangeforwards.LEINumber = value.value;
                                    break;
                                case 'LEI Expiration Date':
                                    objGoreignexchangeforwards.LEIExpirationDate = value.value;
                                    break; */
                        default:
                            break;
                    }
                })
            }

            sessionStorage.setItem('ActualEmailVerified', showEmailVerifiedAlert);
            sessionStorage.setItem('ActualCustomerID', customer.CustomerId);
            sessionStorage.setItem('ActualCustomerName', customer.name);
            sessionStorage.setItem('ActualCustomerEmail', customer.email);

            this.setState({
                objGeneralbusinessinformation,
                objGrincipalplaceofbusiness,
                objGroductsandservices,
                objGoreignexchangeforwards,
                objGccountprimarycontact,
                contactAdditionalProperties,
                objGirectorandappointedofficer,
                contact01AdditionalProperties,
                objGltimatebeneficialowner,
                contact02AdditionalProperties,
                //objGcceptanceoftermsandconditions,
                objGOwners,
                objCustomerAgreement,
                objTaskAttachments_Step1: objTaskAttachments,

                objTaskAttachments_Step5,
                objTaskAttachments_Step6,
                objTaskAttachments_Step7,

                yesDirector,
                noDirector: !yesDirector,
                readOnlyDirector: !yesDirector,
                yesUltimate,
                noUltimate: !yesUltimate,
                readOnlyUltimate: !yesUltimate,

                emailVerified,
                originOnline: originOnline != null ? originOnline : ''
            });
        }
    }
    contactProperty = (obj, name) => {
        let value = '';
        if (obj != null) {
            obj.forEach(element => {
                switch (element.name) {
                    case name:
                        value = element.value != null ? element.value : '';
                        break;
                    default:
                        break;
                }
            });
        }
        return value;
    }

    roleList = (properties) => {
        let _roleList = [];
        properties.forEach(property => {
            switch (property.name) {
                case 'Role Authorizer': {
                    if (property.value != null && property.value.toLowerCase() === 'yes') {
                        _roleList.push('Authorizer');
                    }
                    break;
                }
                case 'Is director?': {
                    if (property.value != null && property.value.toLowerCase() === 'yes') {
                        _roleList.push('Director');
                    }
                    break;
                }
                case 'Is owner?': {
                    if (property.value != null && property.value.toLowerCase() === 'yes') {
                        _roleList.push('UBO');
                    }
                    break;
                }
                default: break;
            }
        });
        return _roleList;
    };

    TaskAttachments_Step1 = (obj) => {
        this.setState({ TaskAttachments_Step1: obj });
    }
    TaskAttachments_Step2 = (obj) => {
        this.setState({ TaskAttachments_Step2: obj });
    }
    TaskAttachments_Step3 = (obj) => {
        this.setState({ TaskAttachments_Step3: obj });
    }
    TaskAttachments_Step4 = (obj) => {
        this.setState({ TaskAttachments_Step4: obj });
    }
    TaskAttachments_Step5 = (obj) => {
        this.setState({ TaskAttachments_Step5: obj });
    }
    TaskAttachments_Step6 = (obj) => {
        this.setState({ TaskAttachments_Step6: obj });
    }
    TaskAttachments_Step7 = (obj) => {
        this.setState({ TaskAttachments_Step7: obj });
    }
    updateValueListAdditionalProppertiesDefs = (listValues, listaValidations, page) => {
        console.log("-------------------------");
        console.log("Llegamos al Update");
        console.log(listValues);
        console.log(listaValidations);
        console.log(page);
        console.log("-------------------------");
        console.log(this.state.valuesOfAdditionalPropertiesDefs1)
        console.log(this.state.validationsOfAdditionalPropertiesDefs1)
        console.log("-------------------------");
        console.log(this.state.valuesOfAdditionalPropertiesDefs2)
        console.log(this.state.validationsOfAdditionalPropertiesDefs2)
        console.log("-------------------------");
        console.log(this.state.valuesOfAdditionalPropertiesDefs3)
        console.log(this.state.validationsOfAdditionalPropertiesDefs3)
        console.log("-------------------------");
        console.log(this.state.valuesOfAdditionalPropertiesDefs4)
        console.log(this.state.validationsOfAdditionalPropertiesDefs4)
        console.log("-------------------------");
        console.log(this.state.valuesOfAdditionalPropertiesDefs5)
        console.log(this.state.validationsOfAdditionalPropertiesDefs5)
        console.log("-------------------------");
        console.log(this.state.valuesOfAdditionalPropertiesDefs6)
        console.log(this.state.validationsOfAdditionalPropertiesDefs6)
        console.log("-------------------------");
        console.log(this.state.valuesOfAdditionalPropertiesDefs7)
        console.log(this.state.validationsOfAdditionalPropertiesDefs7)
        console.log("-------------------------");
        console.log(this.state.valuesOfAdditionalPropertiesDefs8)
        console.log(this.state.validationsOfAdditionalPropertiesDefs8)
        console.log("-------------------------");
    };

    handleUpdateRadioButtons = (event) => {
        const question = event.target.id;
        switch (question) {
            default:
            case 'enabledDirector':
                this.setState({ yesDirector: true, noDirector: false, readOnlyDirector: false });
                break;
            case 'disabledDirector':
                this.state.allAdditionalPropertiesDefs6.forEach(element => { element.value = ''; })
                this.setState({ yesDirector: false, noDirector: true, readOnlyDirector: true });
                break;
            case 'enabledUltimate':
                this.setState({ yesUltimate: true, noUltimate: false, readOnlyUltimate: false });
                break;
            case 'disabledUltimate':
                this.state.allAdditionalPropertiesDefs7.forEach(element => { element.value = ''; })
                this.setState({ yesUltimate: false, noUltimate: true, readOnlyUltimate: true });
                break;
        }
    }
    render() {
        return (
            <form>
                <h2>
                    {this.props.type === 'Corporate' ?
                        getResource('Corporate_Title') :
                        getResource('MSB_Title')
                    }
                </h2>
                {/* { <p className="p-color">{getResource('Disclaimer')}</p> } */}

                <p className="right p-color">{getResource('Field_Required')}</p>

                {getCurrentCompanyName() !== 'Khyber' &&<div class="fab fab-fixed">
                    <button
                        type="button"
                        id="partial_button"
                        className="btn btn-primary"
                        style={{ borderColor: 'white' }}
                        onClick={this.submitPartial}> {getResource('Button_Partial')} </button>
                </div>}

                <div className="accordion-style">
                    <ul id="accordion-contact" uk-accordion="multiple: false; collapsible: true" >
                        <li id="general-business-information-li" className="uk-open accordion-header noPadding15">
                            <h3 id="general-business-information" className={this.handleActiveTab('general-business-information')} data-prev-validate="">
                                <a href="#general-business-information" className="toggle-status">
                                    <i className="fa fa-caret-square-o-down" aria-hidden="true"></i>
                                    {' ' + getResource('Title_Corp_1')}<small className="fr">{getResource("Step1")}</small>
                                </a>
                            </h3>
                            <div className="uk-accordion-content">
                                <section id="general-business-information-wrapper" className="clearfix" style={{ display: "block" }}>
                                    <fieldset disabled={false}>
                                        <GeneralBusinessInformation
                                            Generalbusinessinformation={this.Generalbusinessinformation}
                                            notFirstLoad={this.context.notFirstLoad_Step1}
                                            notFirstLoadPartial={this.context.notFirstLoad_Partial_Step1}
                                            //isValidated={this.isValidated_Step1}
                                            //isPartialValidated={this.isValidated_Partial_Step1}
                                            objGeneralbusinessinformation={this.state.objGeneralbusinessinformation}
                                            clickNext={this.state.clickNext}
                                            NoClickNext={this.NoClickNext}
                                        />
                                        <AdditionalInformation
                                            notFirstLoad={this.context.notFirstLoad_Step1}
                                            totalList={this.state.allAdditionalPropertiesDefs1}
                                            customerAdditionalProperties={this.state.customerAdditionalProperties}
                                            values={this.state.valuesOfAdditionalPropertiesDefs1}
                                            validations={this.state.validationsOfAdditionalPropertiesDefs1}
                                            onUpdateValues={this.updateValueListAdditionalProppertiesDefs}
                                            step={1}
                                            countryID = {this.state.objGrincipalplaceofbusiness !== null ?this.state.objGrincipalplaceofbusiness.countryID : ''}
                                        />
                                        <TaskAttachments
                                            attachmentGenerate={this.state.attachmentGenerate_Step1}
                                            objTaskAttachments={this.state.objTaskAttachments_Step1}
                                            type="Corporate"
                                            TaskAttachments={this.TaskAttachments_Step1}
                                            clickNext={this.state.clickNext}
                                            NoClickNext={this.NoClickNext}
                                        />
                                    </fieldset>
                                </section>
                                {/**NEXT / PREVIOUS */}
                                <div className="">
                                    <button
                                        type="button"
                                        id="previous_button"
                                        className="btn btn-primary"
                                        style={{ float: 'right' }}
                                        onClick={this.NextButton.bind(this, 1)}> {getResource('Button_Next')} </button>
                                </div>
                            </div>
                        </li>
                        <li id="principal-place-of-business-li" className="accordion-header noPadding15" >
                            <h3 id="principal-place-of-business" className={this.handleActiveTab('principal-place-of-business')} data-prev-validate="">
                                <a href="#principal-place-of-business" className="toggle-status">
                                    <i className="fa fa-caret-square-o-down" aria-hidden="true"></i>
                                    {' ' + getResource('Title_Corp_2')}
                                    <small className="fr" onClick={this.OnClickNext}>
                                        {getResource("Step2")}
                                    </small>
                                </a>
                            </h3>
                            <div className="uk-accordion-content">
                                <section id="principal-place-of-business-wrapper" className="clearfix" style={{ display: "block" }}>
                                    <fieldset disabled={false}>
                                        <PrincipalPlaceOfBusiness
                                            Principalplaceofbusiness={this.Principalplaceofbusiness}
                                            notFirstLoad={this.context.notFirstLoad_Step2}
                                            notFirstLoadPartial={this.context.notFirstLoad_Partial_Step2}
                                            //isValidated={this.isValidated_Step2}
                                            //isPartialValidated={this.isValidated_Partial_Step2}
                                            objGrincipalplaceofbusiness={this.state.objGrincipalplaceofbusiness}
                                            clickNext={this.state.clickNext}
                                            NoClickNext={this.NoClickNext}
                                        />
                                        <AdditionalInformation
                                            notFirstLoad={this.context.notFirstLoad_Step2}
                                            totalList={this.state.allAdditionalPropertiesDefs2}
                                            customerAdditionalProperties={this.state.customerAdditionalProperties}
                                            values={this.state.valuesOfAdditionalPropertiesDefs2}
                                            validations={this.state.validationsOfAdditionalPropertiesDefs2}
                                            onUpdateValues={this.updateValueListAdditionalProppertiesDefs}
                                            step={2}
                                        />
                                        <TaskAttachments
                                            attachmentGenerate={this.state.attachmentGenerate_Step2}
                                            objTaskAttachments={this.state.objTaskAttachments_Step2}
                                            type="Corporate"
                                            TaskAttachments={this.TaskAttachments_Step2}
                                            clickNext={this.state.clickNext}
                                            NoClickNext={this.NoClickNext}
                                        />
                                    </fieldset>
                                </section>
                                {/**NEXT / PREVIOUS */}
                                <div className="uk-margin">
                                    <button
                                        type="button"
                                        id="previous_button"
                                        className="btn btn-primary"
                                        onClick={this.PreviousButton.bind(this, 0)}> {getResource('Button_Prev')} </button>
                                    <button
                                        type="button"
                                        id="previous_button"
                                        className="btn btn-primary"
                                        style={{ float: 'right' }}
                                        onClick={this.NextButton.bind(this, 2)}> {getResource('Button_Next')} </button>
                                </div>
                            </div>
                        </li>

                        <li id="account-primary-contact-li" className="accordion-header noPadding15">
                            <h3 id="account-primary-contact" className={this.handleActiveTab('account-primary-contact')} data-prev-validate="">
                                <a href="#account-primary-contact" className="toggle-status">
                                    <i className="fa fa-caret-square-o-down" aria-hidden="true"></i>
                                    {' ' + getResource('Title_Corp_5')}
                                    <small className="fr" onClick={this.OnClickNext}>
                                        {getResource("Step3")}
                                    </small>
                                </a>
                            </h3>
                            <div className="uk-accordion-content">
                                <section id="account-primary-contact-wrapper" className="clearfix" style={{ display: "block" }}>
                                    <fieldset disabled={false}>
                                        <AccountPrimaryContact
                                            Accountprimarycontact={this.Accountprimarycontact}
                                            notFirstLoad={this.context.notFirstLoad_Step3}
                                            notFirstLoadPartial={this.context.notFirstLoad_Partial_Step3}
                                            //isValidated={this.isValidated_Step5}
                                            //isPartialValidated={this.isValidated_Partial_Step5}
                                            objGccountprimarycontact={this.state.objGccountprimarycontact}
                                            clickNext={this.state.clickNext}
                                            NoClickNext={this.NoClickNext}

                                            allAdditionalPropertiesDefs5={this.state.allAdditionalPropertiesDefs3}
                                            //contactAdditionalProperties={this.state.contactAdditionalProperties}
                                            valuesOfAdditionalPropertiesDefs5={this.state.valuesOfAdditionalPropertiesDefs3}
                                            validationsOfAdditionalPropertiesDefs5={this.state.validationsOfAdditionalPropertiesDefs3}
                                            updateValueListAdditionalProppertiesDefs={this.updateValueListAdditionalProppertiesDefs}
                                        />{/* 
                                        <AdditionalInformation
                                            notFirstLoad={this.context.notFirstLoad_Step5}
                                            totalList={}
                                            customerAdditionalProperties={}
                                            values={}
                                            validations={}
                                            onUpdateValues={}
                                            step={5}
                                        /> */}
                                        <TaskAttachments
                                            attachmentGenerate={this.state.attachmentGenerate_Step3}
                                            objTaskAttachments={this.state.objTaskAttachments_Step3}
                                            type="Corporate"
                                            TaskAttachments={this.TaskAttachments_Step3}
                                            clickNext={this.state.clickNext}
                                            NoClickNext={this.NoClickNext}
                                        />
                                    </fieldset>
                                </section>
                                <div className="uk-margin">
                                    {/**NEXT / PREVIOUS */}
                                    <button
                                        type="button"
                                        id="previous_button"
                                        className="btn btn-primary"
                                        onClick={this.PreviousButton.bind(this, 1)}> {getResource('Button_Prev')} </button>
                                    <button
                                        type="button"
                                        id="previous_button"
                                        className="btn btn-primary"
                                        style={{ float: 'right' }}
                                        onClick={this.NextButton.bind(this, 3)}> {getResource('Button_Next')} </button>
                                </div>
                            </div>
                        </li>
                        <li id="directors-li" className="accordion-header noPadding15">
                            <h3 id="directors" className={this.handleActiveTab('directors')} data-prev-validate="">
                                <a href="#directors" className="toggle-status">
                                    <i className="fa fa-caret-square-o-down" aria-hidden="true"></i>
                                    {' ' + getResource('Title_Corp_6')}
                                    <small className="fr" onClick={this.OnClickNext}>
                                        {getResource("Step4")}
                                    </small>
                                </a>
                            </h3>
                            <div className="uk-accordion-content">
                                <section id="directors-wrapper" className="clearfix" style={{ display: "block" }}>
                                    {/* <p className="p-color">{getResource("Label_Director_Subtitle1")}</p> 
                                    <p className="p-color">{getResource("Label_Director_Subtitle2")}</p>  */}
                                    <fieldset disabled={false}>
                                       <DirectorAndAppointedOfficer
                                            Directorandappointedofficer={this.Directorandappointedofficer}
                                            notFirstLoad={this.context.notFirstLoad_Step4}
                                            notFirstLoadPartial={this.context.notFirstLoad_Partial_Step4}
                                            //isValidated={this.isValidated_Step6}
                                            //isPartialValidated={this.isValidated_Partial_Step6}
                                            objGirectorandappointedofficer={this.state.objGirectorandappointedofficer}
                                            clickNext={this.state.clickNext}
                                            NoClickNext={this.NoClickNext}
                                        />
                                    
                                        {/* <AdditionalInformation
                                            notFirstLoad={this.context.notFirstLoad_Step7}
                                            totalList={this.state.allAdditionalPropertiesDefs7}
                                            customerAdditionalProperties={this.state.contact02AdditionalProperties}
                                            values={this.state.valuesOfAdditionalPropertiesDefs7}
                                            validations={this.state.validationsOfAdditionalPropertiesDefs7}
                                            onUpdateValues={this.updateValueListAdditionalProppertiesDefs}
                                            step={7}
                                        />
                                        <TaskAttachments
                                            attachmentGenerate={this.state.attachmentGenerate_Step7}
                                            objTaskAttachments={this.state.objTaskAttachments_Step7}
                                            type="Corporate"
                                            TaskAttachments={this.TaskAttachments_Step7}
                                            clickNext={this.state.clickNext}
                                            NoClickNext={this.NoClickNext}
                                        />
                                        <TaskAttachments
                                            attachmentGenerate={this.state.attachmentGenerate_Step5}
                                            objTaskAttachments={this.state.objTaskAttachments_Step5}
                                            type="Corporate"
                                            TaskAttachments={this.TaskAttachments_Step5}
                                            clickNext={this.state.clickNext}
                                            NoClickNext={this.NoClickNext}
                                        /> */}
                                    </fieldset>
                                </section>
                                <div className="uk-margin">
                                    {/**NEXT / PREVIOUS */}
                                    <button
                                        type="button"
                                        id="previous_button"
                                        className="btn btn-primary"
                                        onClick={this.PreviousButton.bind(this, 2)}> {getResource('Button_Prev')} </button>
                                    <button
                                        type="button"
                                        id="previous_button"
                                        className="btn btn-primary"
                                        style={{ float: 'right' }}
                                        onClick={this.NextButton.bind(this, 4)}> {getResource('Button_Next')} </button>
                                </div>
                            </div>
                        </li>
                        <li id="owners-li" className="accordion-header noPadding15">
                            <h3 id="owners" className={this.handleActiveTab('owners')} data-prev-validate="">
                                <a href="#owners" className="toggle-status">
                                    <i className="fa fa-caret-square-o-down" aria-hidden="true"></i>
                                    {' ' + getResource('Title_Corp_9')}
                                    <small className="fr" onClick={this.OnClickNext}>
                                        {getResource("Step5")}
                                    </small>
                                </a>
                            </h3>
                            <div className="uk-accordion-content">
                                <section id="owners-wrapper" className="clearfix" style={{ display: "block" }}>
                                    <fieldset disabled={false}>
                                        <Owners
                                            Owners={this.Owners}
                                            notFirstLoad={this.context.notFirstLoad_Step5}
                                            notFirstLoadPartial={this.context.notFirstLoad_Partial_Step5}
                                            //isValidated={this.isValidated_Step7}
                                            //isPartialValidated={this.isValidated_Partial_Step7}
                                            objGOwners={this.state.objGOwners}
                                            clickNext={this.state.clickNext}
                                            NoClickNext={this.NoClickNext}
                                        />
                                        {/* <AdditionalInformation
                                            notFirstLoad={this.context.notFirstLoad_Step7}
                                            totalList={this.state.allAdditionalPropertiesDefs7}
                                            customerAdditionalProperties={this.state.contact02AdditionalProperties}
                                            values={this.state.valuesOfAdditionalPropertiesDefs7}
                                            validations={this.state.validationsOfAdditionalPropertiesDefs7}
                                            onUpdateValues={this.updateValueListAdditionalProppertiesDefs}
                                            step={7}
                                        />
                                        <TaskAttachments
                                            attachmentGenerate={this.state.attachmentGenerate_Step7}
                                            objTaskAttachments={this.state.objTaskAttachments_Step7}
                                            type="Corporate"
                                            TaskAttachments={this.TaskAttachments_Step7}
                                            clickNext={this.state.clickNext}
                                            NoClickNext={this.NoClickNext}
                                        />
                                        <TaskAttachments
                                            attachmentGenerate={this.state.attachmentGenerate_Step5}
                                            objTaskAttachments={this.state.objTaskAttachments_Step5}
                                            type="Corporate"
                                            TaskAttachments={this.TaskAttachments_Step5}
                                            clickNext={this.state.clickNext}
                                            NoClickNext={this.NoClickNext}
                                        /> */}
                                    </fieldset>
                                </section>
                                <div className="uk-margin">
                                    {/**NEXT / PREVIOUS */}
                                    <button
                                        type="button"
                                        id="previous_button"
                                        className="btn btn-primary"
                                        onClick={this.PreviousButton.bind(this, 3)}> {getResource('Button_Prev')} </button>
                                    <button
                                        type="button"
                                        id="previous_button"
                                        className="btn btn-primary"
                                        style={{ float: 'right' }}
                                        onClick={this.NextButton.bind(this, 5)}> {getResource('Button_Next')} </button>
                                </div>
                            </div>
                        </li>
                        <li id="acceptance-of-terms-and-conditions-li" className="accordion-header noPadding15">
                            <h3 id="acceptance-of-terms-and-conditions" className={this.handleActiveTab('acceptance-of-terms-and-conditions')} data-prev-validate="">
                                <a href="#acceptance-of-terms-and-conditions" className="toggle-status">
                                    <i className="fa fa-caret-square-o-down" aria-hidden="true"></i>
                                    {' ' + getResource('Title_Corp_8')}
                                    <small className="fr" onClick={this.OnClickNext}>
                                        {getResource("FinalStep")}
                                    </small>
                                </a>
                            </h3>
                            <div className="uk-accordion-content">
                                <section id="acceptance-of-terms-and-conditions-wrapper" className="clearfix" style={{ display: "block" }}>
                                    <fieldset disabled={false}>
                                       {/*} <AcceptanceOfTermsAndConditions
                                            Acceptanceoftermsandconditions={this.Acceptanceoftermsandconditions}
                                            notFirstLoad={this.context.notFirstLoad_Step8}
                                            notFirstLoadPartial={this.context.notFirstLoad_Partial_Step8}
                                            //isValidated={this.isValidated_Step8}
                                            //isPartialValidated={this.isValidated_Partial_Step8}
                                            objGcceptanceoftermsandconditions={this.state.objGcceptanceoftermsandconditions}
                                            clickNext={this.state.clickNext}
                                            NoClickNext={this.NoClickNext}
                                            TermsPDF={this.state.urlTermsPDF}
                                    />*/}
                                    <CustomerAgreement navigate={this.props.navigate}
                                        allObjts={{
                                            Generalbusinessinformation: this.state.generalbusinessinformation,
                                            PrincipalPlaceOfBusiness: this.state.principalplaceofbusiness,
                                            Accountprimarycontact: this.state.accountprimarycontact,
                                            DirectorAndAppointedOfficer: this.state.directorandappointedofficer,
                                            UltimateBeneficialOwner: this.state.ultimatebeneficialowner,
                                            Owners: this.state.owners,
                                            AdditionalProperties: {
                                                step1: this.state.valuesOfAdditionalPropertiesDefs1,
                                                step2: this.state.valuesOfAdditionalPropertiesDefs2,
                                                step3: this.state.valuesOfAdditionalPropertiesDefs3,
                                                step4: this.state.valuesOfAdditionalPropertiesDefs4,
                                                step5: this.state.valuesOfAdditionalPropertiesDefs5,
                                                step6: this.state.valuesOfAdditionalPropertiesDefs6,
                                                step7: this.state.valuesOfAdditionalPropertiesDefs7
                                            }
                                        }}
                                        CustomerAgreement={this.CustomerAgreement}
                                        addProps_CustomerAgreement={this.addProps_CustomerAgreement}
                                        submit360={this.submit360CorporateClick}
                                        notFirstLoad={this.context.notFirstLoad6}
                                        notFirstLoadPartial={this.context.notFirstLoad6}
                                        type="Corporate"
                                        objCustomerAgreement={this.state.objCustomerAgreement}
                                        readOnly={this.state.readOnly}
                                        clickNext={this.state.clickNext}
                                        NoClickNext={this.NoClickNext}
                                        TermsPDF={this.state.urlTermsPDF}
                                    />
                                        <AdditionalInformation
                                            notFirstLoad={this.context.notFirstLoad_Step6}
                                            totalList={this.state.allAdditionalPropertiesDefs6}
                                            customerAdditionalProperties={this.state.customerAdditionalProperties}
                                            values={this.state.valuesOfAdditionalPropertiesDefs6}
                                            validations={this.state.validationsOfAdditionalPropertiesDefs6}
                                            onUpdateValues={this.updateValueListAdditionalProppertiesDefs}
                                            step={6}
                                        />
                                    </fieldset>
                                </section>
                                <div className="uk-margin">
                                    {/**NEXT / PREVIOUS */}
                                    <button
                                        type="button"
                                        id="previous_button"
                                        className="btn btn-primary"
                                        onClick={this.PreviousButton.bind(this, 4)}> {getResource('Button_Prev')} </button>

                                    {/*<button
                                        type="button"
                                        id="previous_button"
                                        className="btn btn-primary"
                                        disabled={this.state.CustomerAgreement != null ? !this.state.CustomerAgreement.customerAgreement : true}
                                        style={{ float: 'right' }}
                                        onClick={this.Submit}> {getResource('Button_Submit')} </button>*/}
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
                <AlertBox
                    open={this.state.emailVerifiedSubmit}
                    onClose={this.closeEmailVerifiedAlert}
                    title="EMAIL VERIFICATION"
                    message="Your email must be verified. Please look for the email that has been sent to you to finish your validation. Do you want confirmation email forwarded to you?"
                    type="Yes/No"
                    yesClick={this.yesEmailVerifiedAlert}
                    noClick={this.closeEmailVerifiedAlert}
                />
                <AlertBox
                    open={this.state.showSentEmailAlert}
                    onClose={this.closeSentEmailAlert}
                    title={this.state.alertTitleSentEmail}
                    message={this.state.alertMessageSentEmail}
                    type="Ok"
                    okClick={this.closeSentEmailAlert}
                />
                <AlertBox
                    open={this.state.showSuccessAlert}
                    onClose={this.closeSuccessAlert}
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    type="Ok"
                    okClick={this.closeSuccessAlert}
                />
                <AlertBox
                    open={this.state.showInvalidReferralAlert}
                    onClose={this.closeInvalidReferralAlert}
                    title='Warning'
                    message='The entered Affiliate Referred by code is not valid.'
                    type="Ok"
                    okClick={this.closeInvalidReferralAlert}
                />
                <AlertBox
                    open={this.state.showFinalSubmitAlert}
                    onClose={this.closeFinalSubmitAlert}
                    title='Info'
                    message='Would you like to download a copy of the signed agreement?'
                    type="Yes/No"
                    yesClick={this.downloadPDF}
                    noClick={this.continueWithFinalSubmit}
                />

                <LoadingBox loading={this.state.loading} />
            </form>
        );
    }
}