import React from 'react';
import { getResource } from '../../helper/ResourcesHelper';
import { getCustomerInfoByID } from '../../networking/Networking';
import { sendValidateEmail, validateEmail } from '../../networking/NetworkingRegForm';
import AlertBox from '../shared/AlertBox';
import LoadingBox from '../shared/LoadingBox';


const valores = window.location.search;
const urlParams = new URLSearchParams(valores);

export default class EmailVerified extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = { 
            type            : '',
            errorMessage    : '',

            //Alert Send
            showSentEmailAlert      : false,
            alertTitleSentEmail     : '',
            alertMessageSentEmail   : '',

            //alert error
            showErrorCustomerAlert      : false,
            alertTitleErrorCustomer      : '',
            alertMessageErrorCustomer    : '',

            //Form
            validationRegistrationNumber    : true,
            registrationNumber              : '',

            customerObject                  : null
        };
    }

    componentDidMount()
    {
        let requestId = urlParams.get('requestId');

        validateEmail(requestId).then(
            (json) => {
                if (json != undefined) 
                {
                    console.log("validateEmail: "+ JSON.stringify(json));
                    let messageE = '';
                    let typeS = '';

                    switch (json.httpStatusCode)    // Verificar error por STATUS
                    {
                        default:
                        case 503: {
                            // Error - validateEmail: {"Message":"ExecuteNonQuery: Connection property has not been initialized.","ErrorMessage":null,"httpStatusCode":503}

                            typeS   = 'Error';
                            messageE    = json.Message;
                            break;
                        }
                        case 200: {
                            // Pending - validateEmail: {"Message":"The email status is Confirmed","ErrorMessage":null,"httpStatusCode":200}
                            // Expired - validateEmail: {"Message":"The email status is Expired","ErrorMessage":null,"httpStatusCode":200}
                            // Url mal - validateEmail: {"Message":"Invalid Request ID","ErrorMessage":null,"httpStatusCode":200}

                            switch (json.Message)   // Verificar el tipo de respuesta
                            {
                                case 'Confirmed': {
                                    typeS = 'Confirmed';
                                    break;
                                }
                                case 'The email status is Confirmed': {
                                    typeS = 'Already confirmed';
                                    break;
                                }
                                case 'The email status is Expired':
                                case 'Request expired': {
                                    typeS = 'Expired';
                                    break;
                                }
                                case 'Invalid Request ID': {
                                    typeS = 'Invalid';
                                    break;
                                }
                                default: {
                                    typeS = 'Error';
                                    break;
                                }
                            }
                            break;
                        }
                    }

                    this.setState({
                        type        : typeS,
                        errorMessage: messageE
                    });
                }
            }
        )
    }

    getCustomerInformationByID = (ID) =>
    {
        getCustomerInfoByID(ID).then(
            (jsonResponse) => {
                if (jsonResponse != null) 
                {
                    console.log("getCustomerInfoByID: " + JSON.stringify(jsonResponse));

                    /*
                        getCustomerInfoByID: 
                        {
                            "CustomerId":9880,
                            "UpdateToken":"637448494566133333",
                            "Status":"Incomplete Online Registration",
                            "type":"Individual",
                            "name":"Andrade 2, Arturo",
                            "salutation":null,
                            "PreferredLanguage":"English",
                            "firstname":"Arturo",
                            "lastname":"Andrade 2",
                            "shortname":"",
                            "email":"andrade.94229@hotmail.com",
                            "phonenumber":"+51922212188",
                            "extension":"",
                            "mobilenumber":"",
                            "fax":"",
                            "industrycode":null,
                            "averagetradesize":0,
                            "monthlyaveragevolume":0,
                            "confirmationdelivery":"Fax",
                            "CommissionCurrency":"USD",
                            "defaultsettlementinstrument":null,
                            "CustomerAddresses":[
                                {
                                    "AddressId":"61E6F865-03A7-4B33-8442-5926783F0582",
                                    "UpdateToken":"637448494566433333",
                                    "type":"Main Address",
                                    "address1":"","address2":"","city":"","countryid":"PER","stateid":null,"statename":"","postalcode":"","pobox":""}
                            ],
                            "Contacts":[
                                {
                                    "ContactId":9512,
                                    "UpdateToken":"637448494566700000",
                                    "AddressId":"A6432175-CB4F-4398-8475-E132EA2C3225",
                                    "AddressUpdateToken":"637448494566933333",
                                    "Salutation":null,
                                    "EmployerName":"",
                                    "position":"",
                                    "lastname":"Andrade 2",
                                    "firstname":"Arturo",
                                    "phonenumber":"",
                                    "dateofbirth":"1910-01-01",
                                    "address1":"",
                                    "address2":"",
                                    "city":"",
                                    "countryid":"PER",
                                    "stateid":null,
                                    "statename":"",
                                    "PostalCode":"",
                                    "email":"andrade.94229@hotmail.com",
                                    "language":"English",
                                    "politicallyexposedperson":"No",
                                    "institutiontype":null,
                                    "ContactProperties":[
                                        {
                                            "name":"PreReg Contact",
                                            "type":"Text",
                                            "value":"yes",
                                            "FilesProperties":null
                                        }
                                    ],
                                    "ContactTask":null
                                }
                            ],
                            "CustomerProperties":[
                                {
                                    "name":"EmailVerified",
                                    "type":"Text","value":"YES","FilesProperties":null
                                },
                                {
                                    "name":"EmailVerifiedOn","type":"DateTime","value":"2020-12-29","FilesProperties":null
                                }
                            ],
                            "CustomerTask":null,
                            "AdditionalProperties":null,
                            "httpStatusCode":200
                        }
                    */
                    if(jsonResponse.CustomerId !== undefined)
                    {
                        this.setState({
                            customerObject: jsonResponse
                        }, () =>{
                            this.sendEmail();
                        });
                    }else
                    {
                        this.setState({
                            showErrorCustomerAlert  : true,
                            alertTitleErrorCustomer : 'Error',
                            alertMessageErrorCustomer   : 'Please enter a valid Registration Number.'

                        });
                    }
                }else{
                    this.setState({
                        showErrorCustomerAlert  : true,
                        alertTitleErrorCustomer : 'Error',
                        alertMessageErrorCustomer   : 'Please enter a valid Registration Number.'

                    });
                }
            }
        );
    }

    sendEmailClick = () =>{
        if(this.state.registrationNumber !== '')
        {
            this.getCustomerInformationByID(this.state.registrationNumber);
        }else
        {
            this.setState({
                validationRegistrationNumber: false
            });
        }
    }

    sendEmail = async () => {
        sendValidateEmail(this.state.customerObject.CustomerId, this.state.customerObject.Contacts[0].firstname, this.state.customerObject.Contacts[0].email).then(
            (jsonResponse) => {
                console.log("sendValidateEmail: " + JSON.stringify(jsonResponse));
                /*
                    sendValidateEmail: {"Message":"","ErrorMessage":null,"httpStatusCode":200}
                */

                let title   = '';
                let message = '';

                if(jsonResponse != null)
                {
                    switch (jsonResponse.httpStatusCode) 
                    {
                        case 200:
                            title = getResource('VerifyEmailTitle');
                            message = 'A new email was sent to the email of the identifier entered. Please check your inbox and confirm it. (Now you can close this window)';
                            break;
                        default:
                            title   = 'Error';
                            message = 'Something went wrong. Please try again later.';
                            break;
                    }
                }else
                {
                    title   = 'Error';
                    message = 'Something went wrong. Please try again later.';
                }

                this.setState({
                    showSentEmailAlert      : true,
                    alertTitleSentEmail     : title,
                    alertMessageSentEmail   : message
                });
            }
        );
    }

    handleUpdateRegistrationNumber = (event) => {
        this.setState({
            registrationNumber          : event.target.value,
            validationRegistrationNumber: true 
        });
    }

    closeSentEmailAlert = () => {
        this.setState({ 
            showSentEmailAlert      : false,
            alertTitleSentEmail     : '',
            alertMessageSentEmail   : ''
        });
    }

    closeErrorCustomerAlert = () => {
        this.setState({ 
            showErrorCustomerAlert      : false,
            alertTitleErrorCustomer     : '',
            alertMessageErrorCustomer   : ''
        });
    }

    render() 
    {
        if(this.state.type === 'Confirmed')
        {
            return (
                <div style={{textAlign:'center'}}>
                    <h3 style={{ fontWeight:'bolder', paddingTop: 20}}>Email verified</h3>
                    <hr></hr>
                    <h4>Your email address was successfully verified.</h4>
                    <p>Please, close this window and return to the Registration form.</p>
                </div>
            );
        }

        if (this.state.type === 'Already confirmed') {
            return (
                <div style={{textAlign:'center'}}>
                    <h3 style={{ fontWeight:'bolder', paddingTop: 20}}>Email already verified</h3>
                    <hr></hr>
                    <h4>Your email address has already been verified.</h4>
                    <p>Please, close this window and return to the Registration form.</p>
                </div>
            );
        }

        if(this.state.type === 'Invalid')
        {
            return (
                <div style={{textAlign:'center'}}>
                    <h3 style={{ fontWeight:'bolder', paddingTop: 20}}>Invalid request identification</h3>
                    <hr></hr>
                    <h4>The URL is not valid. Please check that you opened the full link sent to you by email.</h4>
                </div>
            );
        }

        if(this.state.type === 'Expired')
        {
            return (
                <div style={{textAlign:'center'}}>
                    <h3 style={{ fontWeight:'bolder', paddingTop: 20}}>Sorry, your token expired!</h3>
                    <hr></hr>
                    <h4>We'll need to re-send your authentication email.</h4>
                    <div 
                        style       = {{
                            display         : 'flex',
                            //alignItems      : 'center',
                            flexDirection   : 'row',
                            width           : '100%'
                        }}
                    >
                        <div 
                            style       = {{
                                display         : 'flex',
                                flexDirection   : 'column',
                                paddingLeft     : 0,
                            }}
                            className   = {this.state.validationRegistrationNumber === false ? 'form-group has-error' : 'form-group'}
                        >
                            <input 
                                type                = "text" 
                                className           = "form-control" 
                                id                  = "reg_number" 
                                name                = "reg_number" 
                                maxlength           = "5"
                                value               = {this.state.registrationNumber}
                                onChange            = {this.handleUpdateRegistrationNumber}
                                style               = {{
                                    marginTop: 0,
                                    height: 34
                                }}
                            />
                            <div
                                style = {{
                                    display         : 'flex',
                                    flexDirection   : 'row',
                                    fontSize        : '0.9rem'
                                }}
                            >
                                <label for="reg_number_label" className='small'><b>Registration Number *</b></label>
                                {!this.state.validationRegistrationNumber &&
                                    <label 
                                        style       = {{
                                            paddingLeft     : 5,
                                            color           : 'red',
                                            fontWeight      : 'normal',
                                            fontSize        : '0.9rem'
                                        }}
                                    >Field Required.</label>}
                            </div>
                        </div>

                        <div
                            style       = {{
                                display         : 'flex',
                                flexDirection   : 'column',
                                paddingLeft     : 20
                            }}
                        >
                            <button 
                                className   = "btn btn-primary" 
                                onClick     = {this.sendEmailClick}
                            >RESEND EMAIL</button>
                        </div>
                    </div>
                    <AlertBox 
                        open    = {this.state.showSentEmailAlert} 
                        onClose = {this.closeSentEmailAlert} 
                        title   = {this.state.alertTitleSentEmail} 
                        message = {this.state.alertMessageSentEmail} 
                        type    = "Ok" 
                        okClick = {this.closeSentEmailAlert}
                    />
                    <AlertBox 
                        open    = {this.state.showErrorCustomerAlert} 
                        onClose = {this.closeErrorCustomerAlert} 
                        title   = {this.state.alertTitleErrorCustomer} 
                        message = {this.state.alertMessageErrorCustomer} 
                        type    = "Ok" 
                        okClick = {this.closeErrorCustomerAlert}
                    />
                    <LoadingBox loading={this.state.loading}/>
                </div>
            );
        }
        
        if(this.state.type === '')
        {
            return(
                <div></div>
            );
        }else
        {
            return (
                <div className="container" >
                    <h3 style={{ fontWeight:'bolder'}}>Error</h3>
                    <hr></hr>
                    <p>Something went wrong. Please try again later.</p>
                </div>
            );
        }
    }
}